// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';

import AppbarHomw from '../components/Appbar';
import CompaniesSection from '../components/CompaniesSection';
import HeroSection2 from '../components/Hero2';
import Testimonial from '../components/Testimonial'
import Footer from '../components/Footer';
import HeroProductAbout from '../components/HeroProductAbout';
import HeroProductConatact from '../components/HeroProductContact';
import ContactCard from '../components/ContactCard';
function ContactUs() {
  const firstSectionRef = React.useRef(null);
  React.useEffect(() => {
    // When the component mounts, scroll to the first section
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  return (
    <>
      {/* Appbar  */}
      <div ref={firstSectionRef} style={{ backgroundColor: '#6a34d4', overflowX: 'hidden ' }}>
        <AppbarHomw />
        {/* <HeroProductConatact/> */}
        <ContactCard/>
        <Testimonial margins={0} paddings={20} />
        <div style={{ backgroundColor: 'white', height: '100px' }}>
        </div>
        <Footer />
      </div>

    </>
  );
}

export default ContactUs;
