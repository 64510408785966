import { Avatar, Grid, Typography, Button, List, ListItemIcon, ListItem, ListItemButton, ListItemText } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import logo from "../../assets/logo.png"
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import './stylesheet.css'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import { FooterContent, emailKey, locationKey, phoneNoKey } from '../../urls/contactusKeys';
function Footer() {
    const navigate = useNavigate();
    return (
        <>
{window.innerWidth<730?
            <Grid container spacing={2} style={{ backgroundColor: 'rgb(245 248 250)', height: 'auto', paddingInline: '6%', paddingBlock: '1%' }}>
                <Grid item xs={12} md={4} >
                    {/* <div style={{backgroundColor:'white',width:'50%',borderRadius:'10px'}}> */}
                    <Avatar sx={{ width: "150px", height: 'auto', paddingBlock: '4%' }} alt="Logo" src={logo} variant='square' />

                    {/* </div> */}
                    <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '18px', color: 'gray', fontweight: 100 }}>
                        {FooterContent}
                    </Typography>
                    <Typography className='poppinsSemiRegularBold' variant='h5' style={{ color: '#6a34d4' }}>
                            Company
                        </Typography>
                        {/* <span className='poppinsRegularBold' onClick={()=>navigate('/')} style={{
                            padding: '5px',
                            marginTop: '5px',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',

                        }} > Home</span> */}
                        <div style={{display:"flex",flexDirection:"column"}}>
                            
                     
                        <span onClick={()=>navigate('/products')} className='poppinsRegularBold' style={{
                            padding: '5px',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',

                        }}> Products</span>
                        <span className='poppinsRegularBold' onClick={()=>navigate('/contact_us')}  style={{
                            padding: '5px',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',

                        }}> Contact Us</span>
                         <span className='poppinsRegularBold' onClick={()=>navigate('/privacy_policy')}  style={{
                            padding: '5px',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',

                        }}> Privacy</span>
                         <span className='poppinsRegularBold' onClick={()=>navigate('/terms')}  style={{
                            padding: '5px',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',

                        }}> Terms</span>
                           </div>

                </Grid>
              
                <Grid item xs={12} md={4}  id="footernav">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', padding: 0 }}>
                        <Typography className='poppinsSemiRegularBold' id="headerFooter" variant='h5' style={{ color: '#6a34d4', marginBottom: '10px' }}>
                            Contact us
                        </Typography>
                        <span className='poppinsRegularBold' style={{
                            padding: '5px',
                            display: 'flex',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',
                        }}>
                            <CallIcon style={{marginRight:'4px'}}/>
                            {phoneNoKey}

                        </span>
                        <span className='poppinsRegularBold' style={{
                            padding: '5px',
                            display: 'flex',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',
                        }}>
                            <EmailOutlinedIcon style={{marginRight:'4px'}}/>
                            {emailKey}
                            

                        </span>
                        <span className='poppinsRegularBold' style={{
                            padding: '5px',
                            display: 'flex',
                            // backgroundColor:'red',
                            borderRadius: 8,
                            color: 'gray',
                            cursor: 'pointer',
                        }}>
                            <LocationOnIcon style={{marginRight:'4px'}}/>
                                    {locationKey}
                        
                            

                        </span>
                    </div>
                    {/* <List>
                       
                        <ListItem key='call' disablePadding>
                            <ListItemIcon>
                                <CallIcon />
                            </ListItemIcon>
                            <ListItemText id='sectionFooterList' primary="+1(970)394-1523" />
                        </ListItem>

                        <ListItem key='email' disablePadding>
                            <ListItemIcon>
                                <EmailOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText id='sectionFooterList' primary="info@mtechub.com" />
                        </ListItem>


                        <ListItem key='location_1' disablePadding>
                            <ListItemIcon>
                                <LocationOnIcon />
                            </ListItemIcon>
                            <ListItemText id='sectionFooterList' primary="Office # 11 maryam business center ISB ,PAK" />
                        </ListItem>

                    </List> */}
                </Grid>
            </Grid>:
            <Grid container spacing={2} style={{ backgroundColor: 'rgb(245 248 250)', height: 'auto', paddingInline: '6%', paddingBlock: '1%' }}>
            <Grid item xs={12} md={4} align="left">
                {/* <div style={{backgroundColor:'white',width:'50%',borderRadius:'10px'}}> */}
                <Avatar sx={{ width: "150px", height: 'auto', paddingBlock: '4%' }} alt="Logo" src={logo} variant='square' />

                {/* </div> */}
                <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '18px', color: 'gray', fontweight: 100 }}>
                    {FooterContent}
                </Typography>

            </Grid>
            <Grid item xs={12} md={4} align={"left"} id="footernav" >


                {/* <List> */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', padding: 0 }}>
                    <Typography className='poppinsSemiRegularBold' variant='h5' style={{ color: '#6a34d4' }}>
                        Company
                    </Typography>
                    {/* <span className='poppinsRegularBold' onClick={()=>navigate('/')} style={{
                        padding: '5px',
                        marginTop: '5px',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',

                    }} > Home</span> */}
                    <span onClick={()=>navigate('/products')} className='poppinsRegularBold' style={{
                        padding: '5px',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',

                    }}> Products</span>
                    <span className='poppinsRegularBold' onClick={()=>navigate('/contact_us')}  style={{
                        padding: '5px',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',

                    }}> Contact Us</span>
                     <span className='poppinsRegularBold' onClick={()=>navigate('/privacy_policy')}  style={{
                        padding: '5px',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',

                    }}> Privacy</span>
                     <span className='poppinsRegularBold' onClick={()=>navigate('/terms')}  style={{
                        padding: '5px',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',

                    }}> Terms</span>

                </div>

                {/* <ListItem style={{cursor:'pointer'}} onClick={()=>navigate('/')} key='home' disablePadding>
                        <ListItemText  id='sectionFooterList' primary="Home" />
                    </ListItem>

                    <ListItem style={{cursor:'pointer'}} onClick={()=>navigate('/products')} key='products' disablePadding>

                        <ListItemText id='sectionFooterList' primary="Products" />
                    </ListItem>

                    <ListItem key='contact_us' disablePadding>

                        <ListItemText id='sectionFooterList' primary="Contact Us" />
                    </ListItem>

                    <ListItem key='blogs' disablePadding>

                        <ListItemText id='sectionFooterList' primary="Blogs" />
                    </ListItem>

                </List> */}
            </Grid>
            <Grid item xs={12} md={4} align="left" id="footernav">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', padding: 0 }}>
                    <Typography className='poppinsSemiRegularBold' id="headerFooter" variant='h5' style={{ color: '#6a34d4', marginBottom: '10px' }}>
                        Contact us
                    </Typography>
                    <span className='poppinsRegularBold' style={{
                        padding: '5px',
                        display: 'flex',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',
                    }}>
                        <CallIcon style={{marginRight:'4px'}}/>
                        {phoneNoKey}

                    </span>
                    <span className='poppinsRegularBold' style={{
                        padding: '5px',
                        display: 'flex',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',
                    }}>
                        <EmailOutlinedIcon style={{marginRight:'4px'}}/>
                        {emailKey}
                        

                    </span>
                    <span className='poppinsRegularBold' style={{
                        padding: '5px',
                        display: 'flex',
                        // backgroundColor:'red',
                        borderRadius: 8,
                        color: 'gray',
                        cursor: 'pointer',
                    }}>
                        <LocationOnIcon style={{marginRight:'4px'}}/>
                                {locationKey}
                    
                        

                    </span>
                </div>
                {/* <List>
                   
                    <ListItem key='call' disablePadding>
                        <ListItemIcon>
                            <CallIcon />
                        </ListItemIcon>
                        <ListItemText id='sectionFooterList' primary="+1(970)394-1523" />
                    </ListItem>

                    <ListItem key='email' disablePadding>
                        <ListItemIcon>
                            <EmailOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText id='sectionFooterList' primary="info@mtechub.com" />
                    </ListItem>


                    <ListItem key='location_1' disablePadding>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText id='sectionFooterList' primary="Office # 11 maryam business center ISB ,PAK" />
                    </ListItem>

                </List> */}
            </Grid>
        </Grid> }


        </>
    );
}

export default Footer;