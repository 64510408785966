import React from 'react';

const ErrorPagePayment = () => {
    return (
        <div>
            <h1>Payment Unsuccessful</h1>
            <p>We're sorry, but your payment was unsuccessful.</p>
            <p>Please try again later or contact customer support for assistance.</p>
        </div>
    );
};

export default ErrorPagePayment;