// import logo from './logo.svg';
import * as React from 'react';
import '../../App.css'
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../assets/logo.png'
import { Divider, Drawer, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Stack } from '@mui/material';
import imageDummy from "../../assets/imageDummy.jpg"
import { useNavigate } from 'react-router-dom';
import { loginPageUnLoggedUser, signupPageUnLoggedUser, salt } from '../../urls/apikeys';
import CryptoJS from 'crypto-js';
import LogoutIcon from '@mui/icons-material/Logout';
import {ClipLoader} from "react-spinners"

function AppbarHomw() {
  const navigate = useNavigate()
  const [pages, setPages] = React.useState([
    { name: 'Home', dropdown: 'no', key: 1, navigate: '/' },
    { name: 'About', dropdown: 'no', key: 2, navigate: '/about_us' },
    { name: 'Products', dropdown: 'no', key: 3, navigate: '/products' },
    { name: 'Contact us', dropdown: 'no', key: 4, navigate: '/contact_us' },
  ]);
  //Navbar Menu Demo
  // const [menuItem, setMenuItem] = React.useState([
  //   { header: 'Bussiness', icon: imageDummy, paragraph: "here is some para here iedhhfnhjjsdfgjdsfdfdf", key: 1 },
  //   { header: 'Education', icon: imageDummy, paragraph: "here is some para", key: 2 },
  //   { header: 'Home', icon: imageDummy, paragraph: "here is some para", key: 3 },
  //   { header: 'Education', icon: imageDummy, paragraph: "here is some para", key: 4 },
  // ]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // menu Products 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  // drawer small screen 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginRight: '10px' }}>
        <Avatar sx={{ width: "60%", objectFit: 'contain', height: 'auto', marginLeft: '18px',cursor:'pointer' }} alt="Logo" src={logo} variant='square' 
          
          />

        {/* <Avatar sx={{ display: { xs: 'block', md: 'none' }, width: "100px", height: '50px',padding:'10px' }} alt="Logo" src="https://code205.com/wp-content/uploads/2020/12/1.png" variant='square' /> */}
        <CloseIcon onClick={() => { toggleDrawer(anchor, false) }} />
      </Box>

      <List>
        {pages.map((page) => (
          <>

            <ListItem key={page.key} disablePadding>

              <ListItemButton onClick={() => navigate(page.navigate)}>
                {/* <ListItemIcon>
              Home
            </ListItemIcon> */}
                <ListItemText primary={page.name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>
        ))}

      </List>

    </Box>
  );
  // Menu hover 

  const [profile, setProfile] = React.useState(false)
  const [userEmail, setEmail] = React.useState('')
  // menu 
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const Logout = () => {
    setLoaderLogout(true)
    setTimeout(() => {
      localStorage.removeItem('@UserSession')
      localStorage.removeItem('@PageRefreshed')
      setProfile(false)
      setAnchorEl(null);
    setLoaderLogout(false)

    }, 1000);

  }
  const [loader, setLoader] = React.useState(false)
  const [loaderLogout, setLoaderLogout] = React.useState(false)

  React.useEffect(() => {
    console.log('menu item')
    // get localstorage 

    const localMenuItem = localStorage.getItem('@UserSession')
    console.log(localMenuItem)
    if (localMenuItem === undefined || localMenuItem === null || localMenuItem === '') {
      setProfile(false)
    } else {
      setProfile(true)
      setEmail(JSON.parse(localMenuItem).user.user_name)
    }
  }, [])

  return (
    <>
      {/* Appbar  */}
      <AppBar position="fixed" style={{
        top: 0,
        backgroundColor: '#f5f8fa',
        boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
        borderBottom: '0.5px solid rgba(198,198,198,1);'
      }}>
        <Container maxWidth="xl" >
          <Toolbar disableGutters>
            <Avatar sx={{ display: { xs: 'none', md: 'block' }, width: "150px", height: 'auto',cursor:'pointer' }} alt="Logo" src={logo} variant='square' onClick={()=>{
window.location.href='/'
        }}/>


            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleOpenNavMenu}
                onClick={toggleDrawer('left', true)}

                color="inherit"
              >
                <MenuIcon style={{ color: 'black' }} />
              </IconButton>
              {/* <Avatar sx={{  display: { xs: 'block', md: 'none' } , width: "100%", height: 'auto'}} alt="Logo" src="https://code205.com/wp-content/uploads/2020/12/1.png" variant='square'  /> */}

              {/* small  */}
              <Drawer
                anchor='left'
                open={state['left']}
                onClose={toggleDrawer('left', false)}
              >
                {list('left')}
              </Drawer>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.key} onClick={() => {
                    setTimeout(() => {
                      setLoader(true)
                      navigate(page.navigate);
                    }, 1000);
                  }}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>

            </Box>



            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>

              {pages.map((page) => (
                <Button
                  endIcon={page.dropdown === 'yes' ? <ArrowDropDownIcon /> : null}
                  className='poppinsSemiRegularBold'
                  key={page.key}
                  onClick={(event) => {
                    setTimeout(() => {
                      setLoader(true)
                      navigate(page.navigate);
                    }, 1000);
                    // window.open(`${BASE_URL}${page.navi}`)

                    // if (page.dropdown === 'yes') {
                    //   handleClick(event)
                    // } else {

                    // }
                  }}
                  sx={{
                    my: 3,
                    color: 'black',
                    display: 'flex',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    textAlign: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',

                    '&:hover': {
                      color: '#6a34d4', // Change text color to blue on hover
                    },
                  }}
                >
                  {page.name}
                </Button>
              ))}

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {profile ? <>
                <Box
                  onClick={handleClick2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                  <Avatar alt={userEmail.charAt(0).toUpperCase() + userEmail.slice(1)}
                    src={userEmail.charAt(0).toUpperCase() + userEmail.slice(1)}
                    sx={{ width: 42, height: 42, backgroundColor: '#1890ff' }} />
                  <Typography className='poppinsSemiRegularBold' style={{ fontSize: '16px', color: 'black', marginLeft: '10px' }}>
                    {userEmail}
                  </Typography>

                </Box>
                {/* menu Logout  */}
                <Menu
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      marginTop: '10px',
                      boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                    },
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    className='poppinsRegularBold'
                    style={{ backgroundColor: 'transparent' }} onClick={() => Logout()}>
                    <ListItemIcon>
                      {loaderLogout ?
                        <ClipLoader color="gray" loading={loaderLogout} size={20} /> :
                        <LogoutIcon fontSize="small" />}
                    </ListItemIcon>

                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </> :
                <>
                  <Button
                    onClick={() => {
                      const redirectUrl = `${window.location.origin}`;
                      // const hashedURL = SHA256(redirectUrl + salt).toString();
                      const hashedURL = CryptoJS.AES.encrypt(redirectUrl, salt).toString();
                      window.open(`${signupPageUnLoggedUser}${hashedURL}`)
                    }
                    }
                    className='poppinsRegularBold'
                    sx={{
                      width: '100px',
                      display: { xs: 'none', md: 'block' },
                      color: '#6a34d4',
                      fontSize: '16px',
                      border: '1px solid #6a34d4',
                      borderRadius: '10px',
                      fontWeight: 700,
                      textTransform: 'capitalize',
                      transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                      '&:hover': {
                        backgroundColor: "#6a34d4",
                        color: 'white', // Change text color to blue on hover
                        transitionDelay: '0.3s', // Add transition delay for hover
                      },

                    }} >Signup</Button>
                  {/* <TextButton text="Signup"/> */}
                  {/* <ContainedButton text="Login"/> */}
                  <Button
                    onClick={() => {
                      const redirectUrl = `${window.location.origin}`;
                      // const hashedURL = SHA256(redirectUrl + salt).toString();
                      const hashedURL = CryptoJS.AES.encrypt(redirectUrl, salt).toString();
                      window.open(`${loginPageUnLoggedUser}${hashedURL}`)
                    }
                    }
                    className='poppinsRegularBold'
                    sx={{
                      width: '100px',
                      color: 'white',
                      bgcolor: "#6a34d4",
                      borderRadius: '10px',
                      fontSize: '16px',
                      fontWeight: 700,
                      transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                      '&:hover': {
                        bgcolor: "white",
                        color: '#6a34d4',
                        border: '1px solid #6a34d4', // Change text color to blue on hover
                        transitionDelay: '0.3s', // Add transition delay for hover
                      },
                      marginLeft: '10px',
                      textTransform: 'capitalize'

                    }}>Login</Button>
                </>}



            </Box>
          </Toolbar>
        </Container>
      </AppBar>



    </>
  );
}

export default AppbarHomw;
