import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import ScrollAnimation from 'react-animate-on-scroll';
import StarIcon from '@mui/icons-material/Star';
import { BASE_URL, get } from '../../urls/api';
import { useNavigate } from 'react-router-dom';
import { productsHead, productsParaPart1, productsParaPart2 } from '../../urls/contactusKeys';
function Features2({ productALL }) {
    const navigate = useNavigate()
    const [loader, setLoader] = React.useState(true)
    const [products, setProducts] = React.useState([])
    const fetchProducts = async () => {

        try {
            const apiData = await get('product/getAllProducts'); // Specify the endpoint you want to call
            console.log(apiData)
            if (apiData.error) {
                console.log("error true")
                setLoader(false)

            } else {
                console.log(apiData.result)

                if (productALL === "all") {
                    setProducts(apiData.result)
                    setLoader(false)

                } else {
                    let arrayOfObjects = apiData.result
                    let firstFiveObjects = arrayOfObjects.slice(0, 6);
                    setProducts(firstFiveObjects)
                    setLoader(false)
                }
            }
        } catch (error) {
            setLoader(false)

        }

    }
    
    React.useEffect(() => {
        fetchProducts();
    }, []);
    return (
        <>
            {loader ? <>
                <Grid container spacing={2} pt={10} style={{ backgroundColor: productALL === "all" ? "white" : "transparent" }}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Grid item xs={12} md={2}>
                    </Grid>
                    <Grid item xs={12} md={8} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} align="center">
                                {/* <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34' }}>
                            Our Product
                            </Typography> */}
                                <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h3' style={{ color: '#6a34d4', marginBottom: '20px', width: '100%' }}>
                                   {productsHead}
                                </Typography>
                                <Typography className="poppinsRegularBold" style={{ fontSize: '15px', marginTop: '10px' }}>
                                   {productsParaPart1} <br /> {productsParaPart2}

                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton variant="rectangular" height={300} />

                            </Grid>
                        </Grid>
                        {/* For other variants, adjust the size with `width` and `height` */}
                        {/* <Skeleton variant="rectangular" />
                        <Skeleton variant="rounded" width={210} height={60} /> */}
                    </Grid>
                    <Grid item xs={12} md={2}>
                    </Grid>
                </Grid>
            </> :
                <>
                    <Grid container spacing={2} pt={10} style={{ backgroundColor: productALL === "all" ? "white" : "transparent" }}>
                        <Grid item xs={12} md={2}>
                        </Grid>
                        <Grid item xs={12} md={8} >
                            <Grid container>
                                <Grid item xs={12} md={12} align="center">
                                    {/* <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34' }}>
                            Our Product
                            </Typography> */}
                                    <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h3' style={{ color: '#6a34d4', marginBottom: '20px', width: '100%' }}>
                                    {productsHead}
                                    </Typography>
                                    <Typography className="poppinsRegularBold" style={{ fontSize: '15px', marginTop: '10px' }}>
                                    {productsParaPart1} <br /> {productsParaPart2} 

                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12} align="center" style={{ marginTop: '5%' }}>
                                    <Grid container spacing={1}>
                                        {products.map((feature) => (
                                            <>

                                                <Grid item xs={12} md={4} mt={2} align="center">
                                                    <ScrollAnimation animateIn="fadeIn">


                                                        {/* Card  */}
                                                        <Card

                                                            sx={{
                                                                maxWidth: 345,
                                                                boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                                                                borderRadius: '20px',
                                                                cursor: 'pointer',
                                                                transition: 'transform 0.3s', // Add transition for zoom effect
                                                                '&:hover': {
                                                                    transform: 'scale(1.02)' // Zoom effect on hover
                                                                }
                                                            }}>
                                                            {/* <CardHeader
                                                        avatar={
                                                            <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                                                                R
                                                            </Avatar>
                                                        }
                                                        action={
                                                            <IconButton aria-label="settings">
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                        title="Shrimp and Chorizo Paella"
                                                        subheader="September 14, 2016"
                                                    /> */}
                                                            <Box style={{ padding: '20px' }} onClick={() => navigate(`/product_detail/${feature.product_id}`)}>
                                                                <CardMedia
                                                                    component="img"
                                                                    style={{
                                                                        border: '1px solid lightGray',
                                                                        borderRadius: '10px'
                                                                    }}
                                                                    height="194"
                                                                    image={`${BASE_URL}${feature.image}`}
                                                                    alt="Paella dish"
                                                                />
                                                            </Box>
                                                            <CardContent style={{ textAlign: 'left' }}>
                                                                <div onClick={() => navigate(`/product_detail/${feature.product_id}`)}>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: '20px',
                                                                            color: '#6a34d4',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            maxWidth: '25ch',
                                                                        }}
                                                                        title={feature.product_name.length > 25 ? feature.product_name : null}
                                                                    >
                                                                        {feature.product_name.length > 25
                                                                            ? `${feature.product_name.slice(0, 25)}...`
                                                                            : feature.product_name}
                                                                    </Typography>
                                                                    <Typography
                                                                        className="poppinsRegularBold"
                                                                        style={{
                                                                            fontSize: '15px',
                                                                            marginTop: '10px',
                                                                            whiteSpace: 'wrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            maxWidth: '60ch',
                                                                        }}
                                                                        title={feature.description.length > 60 ? feature.description : null}
                                                                    >
                                                                        {/* {feature.description} */}
                                                                        {feature.description.length > 60
                                                                            ? `${feature.description.slice(0, 60)}...`
                                                                            : feature.description}

                                                                    </Typography>
                                                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>

                                                                        <div style={{ display: 'flex' }}>
                                                                            <Typography className="poppinsRegularBold" style={{ fontSize: '15px', fontWeight: 600, }}>
                                                                                Start at : $ {feature.price}

                                                                            </Typography>
                                                                        </div>
                                                                        <div style={{ display: 'flex' }}>

                                                                            <StarIcon style={{ color: 'orange' }} />
                                                                            <Typography className="poppinsRegularBold" style={{ fontSize: '15px' }}>
                                                                                (5)

                                                                            </Typography>
                                                                        </div>
                                                                    </span>
                                                                    <Divider style={{ marginTop: '10px' }} />

                                                                </div>
                                                                <Box style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                                                    <Button
                                                                        onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                                                                        className='poppinsRegularBold'
                                                                        sx={{
                                                                            // width: '100px',
                                                                            paddingInline: '20px',
                                                                            color: '#6a34d4',
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            borderRadius: '50px',
                                                                            textTransform: 'capitalize',
                                                                            transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                                                            transitionDelay: '0.3s', // Add transition delay for hover
                                                                            border: '1px solid white',

                                                                            '&:hover': {
                                                                                // backgroundColor: "#007aff",,
                                                                                border: '1px solid #6a34d4',
                                                                                color: '#6a34d4', // Change text color to blue on hover
                                                                            },

                                                                        }} >View Detail</Button>
                                                                    <Button
                                                                        onClick={() => navigate(`/product_detail/${feature.product_id}/#pricing`)}
                                                                        className='poppinsRegularBold'
                                                                        sx={{
                                                                            // width: '100px',
                                                                            paddingInline: '20px',
                                                                            color: '#6a34d4',
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            borderRadius: '50px',
                                                                            textTransform: 'capitalize',
                                                                            transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                                                            transitionDelay: '0.3s', // Add transition delay for hover
                                                                            border: '1px solid white',

                                                                            '&:hover': {
                                                                                // backgroundColor: "#007aff",,
                                                                                border: '1px solid #6a34d4',
                                                                                color: '#6a34d4', // Change text color to blue on hover
                                                                            },

                                                                        }} >Buy now</Button>


                                                                </Box>
                                                            </CardContent>
                                                            {/* <CardActions disableSpacing>
                                                        <IconButton aria-label="add to favorites">
                                                            <FavoriteIcon />
                                                        </IconButton>
                                                        <IconButton aria-label="share">
                                                            <ShareIcon />
                                                        </IconButton>
                                                      
                                                    </CardActions> */}
                                                        </Card>
                                                        {/* End  */}
                                                    </ScrollAnimation>

                                                </Grid>
                                            </>))}
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} md={2}>

                                </Grid>


                            </Grid>


                        </Grid>
                        <Grid item xs={12} md={2}>
                        </Grid>
                        {productALL === "all" ? <>
                        </> :
                            <>
                                <Grid item xs={12} md={12} align='center' style={{ marginTop: '2%', marginBottom: '5%' }}>
                                    <ContainedButton text="Explore All"
                                    onClick={()=>navigate('/products')} />

                                </Grid>
                            </>}


                    </Grid>
                </>}

        </>
    );
}

export default Features2;