import React, { useEffect } from 'react';
import { post } from '../urls/api';

const SuccessPagePayment = () => {
    const successSubscription = async (subscriptionId) => {

  // Clear the cart after a successful payment
        const postData = {
            // amount: 10000,
            // paymentMethod: id,
            // paymentMethodId: id,
            // user_email: email,
            subscription_id: subscriptionId,
            user_id: "100004"
            // selected_priceId: "price_1NY1EuGui44lwdb4IX632ZW2",
            // "user_Id": "100004"
        }
const apiData = await post('success-subscription', postData); // Specify the endpoint you want to call
console.log(apiData)
if (apiData.error) {
console.log("error true")
// setLoader(false)

} else {
}

    }
    useEffect(() => {
        // Get the current URL
const url = new URL(window.location.href);

// Use URLSearchParams to parse the query string
const params = new URLSearchParams(url.search);

// Get the subscription_id parameter
const subscriptionId = params.get('subscription_id');

console.log('Subscription ID:', subscriptionId);
successSubscription(subscriptionId)
      
    }
    , []);
    return (
        <div>
            <h1>Payment Successful!</h1>
            <p>Thank you for your payment. Your transaction was successful.</p>
            {/* Add any additional content or components here */}
        </div>
    );
};

export default SuccessPagePayment;