// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';

import AppbarHomw from '../components/Appbar';
import HeroSection from '../components/Hero';
import CompaniesSection from '../components/CompaniesSection';
import SliderSection from '../components/Slider';
import Features from '../components/Features';
import HeroSection2 from '../components/Hero2';
import Features2 from '../components/Products2';
import Testimonial from '../components/Testimonial'
import Footer from '../components/Footer';
import HeroProduct from '../components/HeroProduct';

function Products() {


  const firstSectionRef = React.useRef(null);

  React.useEffect(() => {
    // When the component mounts, scroll to the first section
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts



  return (
    <>
       
      {/* Appbar  */}
      <div ref={firstSectionRef} style={{backgroundColor:'#f5f8fa',overflowX:'hidden '}}>
        <AppbarHomw />
      <div >

     <HeroProduct />
     </div>
     {/* <CompaniesSection/>
     <HeroSection2/> */}
     <Features2 productALL="all"/>
     {/* <CompaniesSection/> */}
     {/* <SliderSection/> */}
     {/* <Features/> 
     <SliderSection/>
     <Testimonial/> */}
      <Testimonial margins={0} paddings={20}/>
     <div style={{backgroundColor:'white',height:'100px'}}>
        
        </div>
     <Footer/>
      </div>
    
    </>
  );
}

export default Products;
