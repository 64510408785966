// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';

import AppbarHomw from '../components/Appbar';
import HeroSection from '../components/Hero';
import CompaniesSection from '../components/CompaniesSection';
import SliderSection from '../components/Slider';
import Features from '../components/Features';
import HeroSection2 from '../components/Hero2';
import Features2 from '../components/Products2';
import Testimonial from '../components/Testimonial'
import Footer from '../components/Footer';
import { salt } from '../urls/apikeys';
import CryptoJS from 'crypto-js';
import { post } from '../urls/api';

function HomePage() {


  const firstSectionRef = React.useRef(null);

  // React.useEffect(() => {
  //   // When the component mounts, scroll to the first section
  //   if (firstSectionRef.current) {
  //     firstSectionRef.current.scrollIntoView({
  //       behavior: 'smooth',

  //     });
  //   }
  // }, []); // The empty dependency array ensures this effect runs only once when the component mounts
  const getUserAndStoreToLocal = async (email) => {
    const dataPost = {
        email: email
    }
    const apiData = await post('user/getUserByEmail', dataPost); // Specify the endpoint you want to call
    console.log("User Details")
    console.log(apiData)
    if (apiData.error) {
    } else {
        localStorage.setItem("@UserSession", JSON.stringify({ user: apiData.result }));
        if (!localStorage.getItem("@PageRefreshed")) {
          localStorage.setItem("@PageRefreshed", "true");
          window.location.reload();
        }
      }
}
  React.useEffect(() => {
    const hasWebHash = window.location.hash.includes("id");
    if (hasWebHash) {
        const hashValue = window.location.hash.split("=")[1];

        console.log('email ', hashValue)
        //   decode it 
        const bytes = CryptoJS.AES.decrypt(hashValue, salt);
        const decryptedURL = bytes.toString(CryptoJS.enc.Utf8);
        console.log("bytes")
        console.log(decryptedURL)
        // setEmail(decryptedURL);
        getUserAndStoreToLocal(decryptedURL)
        // Do something if the hash contains "web"
        //   local storage store 

    } else {
        //   setHashed(false)
        console.log('no email ')

        // Do something else if the hash doesn't contain "web"
    }
    if (firstSectionRef.current) {
          firstSectionRef.current.scrollIntoView({
            behavior: 'smooth',
    
          });
        }


}, []);

  return (
    <>

      {/* Appbar  */}
      <div style={{ backgroundColor: '#f5f8fa', overflowX: 'hidden ' }} ref={firstSectionRef}>
        <AppbarHomw />
        <HeroSection />
        {/* <CompaniesSection /> */}
        <HeroSection2 />
        <Features2 productALL="firstFour" />
        <Features />
        <SliderSection />
        <Testimonial margins={30} paddings={0} />
        <div style={{ backgroundColor: 'white', height: '100px' }}>

        </div>
        <Footer />
      </div>

    </>
  );
}

export default HomePage;
