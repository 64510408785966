import { Avatar, Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import imageDummy from "../../assets/imageDummy.jpg"
import imageDummy1 from "../../assets/imageDummy2.jpg"
import imageDummy2 from "../../assets/imageDummy3.jpg"
import arrow from "../../assets/arrow.png"
import arrow1 from "../../assets/arrow1.png"
import { useParams } from 'react-router-dom';
import { BASE_URL, post } from '../../urls/api';


function ProductHero({ name, description, image,pricingSection1 }) {


    return (
        <>
            <Box >
                <Grid container spacing={2} style={{ backgroundColor: 'white', height: '85vh', overflow: 'visible', marginTop: '4%' }}>

                    {/* <Grid item xs={12} md={2}>
                            </Grid> */}
                    {name.length === 0 || description.length == 0 ? <>
                        <Grid item xs={12} md={6} align="center" style={{ padding: '50px', width: '90%', height: 'auto', borderRadius: '30px', textAlign: 'center' }}>

                            <Skeleton variant="rectangular" height={300} />


                        </Grid>
                        <Grid item xs={12} md={6} align="center" style={{ padding: '50px', width: '90%', height: 'auto', borderRadius: '30px', textAlign: 'center' }}>

                            <Skeleton variant="rectangular" height={300} />


                        </Grid>
                    </> :
                        <>
{window.innerWidth<730? 
 <Grid item xs={12} md={12} align="center" style={{ padding: '50px', width: '90%', height: 'auto', borderRadius: '30px', textAlign: 'center' }}>
                                <Grid container spacing={1}>
                                 <Grid item xs={12} md={6} id="HeroImageBorderRadius" align="center" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginTop:"10px" }} >
                                     
                                     <Avatar src={`${BASE_URL}${image}`} variant='square' style={{ width: '250px', marginBlock: '2%', height: '250px',objectFit:"contain", borderRadius: '15px' }} alt='ImageHero' /> 
  <Typography className='poppinsSemiRegularBold' id="header1" variant='h1' style={{ color: 'white', marginBottom: '10px' }}>
                                            {name}
                                        </Typography>
                                        <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                                            <span title={description}>
                                                {description.length > 100 ? `${description.substring(0, 100)}...` : description}
                                            </span>
                                        </Typography> 
                                         <span style={{ marginBlock: '5%' }}>
                                            {/* <ContainedButton text="Subscribe Now" /> */}
                                            <Button
                                    className='poppinsSemiRegularBold'
                                    // id="containedButton"
                                    onClick={()=>{
          pricingSection1.scrollIntoView({ behavior: 'smooth' });
                                        
                                    }}
                                    sx={{
                                        // width: '250px',
                                        backgroundColor: 'white',
                                        color: '#6a34d4',
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        padding: '10px 20px',
                                        borderRadius: '10px',
                                        textTransform: 'none',
                                        // bgcolor: "#007aff",
                                        // borderRadius: '50px',
                                        // fontSize: '19px',
                                        transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                        '&:hover': {
                                            bgcolor: '#6a34d4',
                                            color: 'white',
                                            border: '1px solid white', // Change text color to blue on hover
                                            transitionDelay: '0.2s', // Add transition delay for hover
                                       
                                        },
                                    }}
                                >Subscribe Now</Button>
                                        </span>
                                   </Grid>
                     
                          
                                </Grid>
                            </Grid>:
                              <Grid item xs={12} md={12} align="center" style={{ padding: '50px', width: '90%', height: 'auto', borderRadius: '30px', textAlign: 'center' }}>
                              <Grid container spacing={1}>
                            
                                  <Grid item xs={12} md={6} id="smallScreenBorderRadius" style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                      <Typography className='poppinsSemiRegularBold' id="header1" variant='h1' style={{ color: 'white', marginBottom: '10px' }}>
                                          {name}
                                      </Typography>
                                      <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                                          <span title={description}>
                                              {description.length > 100 ? `${description.substring(0, 100)}...` : description}
                                          </span>
                                      </Typography>
                                      <span style={{ marginBlock: '5%' }}>
                                          {/* <ContainedButton text="Subscribe Now" /> */}
                                          <Button
                                  className='poppinsSemiRegularBold'
                                  // id="containedButton"
                                  onClick={()=>{
        pricingSection1.scrollIntoView({ behavior: 'smooth' });
                                      
                                  }}
                                  sx={{
                                      // width: '250px',
                                      backgroundColor: 'white',
                                      color: '#6a34d4',
                                      fontWeight: 700,
                                      fontSize: '20px',
                                      padding: '10px 20px',
                                      borderRadius: '10px',
                                      textTransform: 'none',
                                      // bgcolor: "#007aff",
                                      // borderRadius: '50px',
                                      // fontSize: '19px',
                                      transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                      '&:hover': {
                                          bgcolor: '#6a34d4',
                                          color: 'white',
                                          border: '1px solid white', // Change text color to blue on hover
                                          transitionDelay: '0.2s', // Add transition delay for hover
                                     
                                      },
                                  }}
                              >Subscribe Now</Button>
                                      </span>



                                  </Grid>
                                <Grid item xs={12} md={6} id="HeroImageBorderRadius" align="center" style={{ height: '75vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                   
                                    <Avatar src={`${BASE_URL}${image}`} variant='square' style={{ width: '600px', marginBlock: '12%', height: 'auto', borderRadius: '15px' }} alt='ImageHero' /> 

                                  </Grid>
                              </Grid>
                          </Grid>
}
                          
                        </>}

                </Grid>
            </Box>
        </>
    );
}

export default ProductHero;