import { Avatar, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import circle from "../../assets/ProductHero.png"
import DummyImage from "../../assets/imageDummy3.jpg"
import meet from "../../assets/meet.png"
import teams from "../../assets/teams.png"
import zoom from "../../assets/zoom.png"
import './stylesheet.css'
import { productsHeaderHero, productsParaHero } from '../../urls/contactusKeys';





function HeroProduct() {
    return (
        <>

            <Grid container spacing={2}
                id="smallScreenHero"
                style={{
                    marginTop: 30,
                    backgroundColor:"#6a34d4",  // backgroundColor:'red',
                    
                    // height: '100vh',
                    display: 'flex',
                    alignItems: 'center',


                }}> 
                   <Grid item xs={12} md={1}>
                </Grid>
                 <Grid item xs={12} md={5} mt={-5} id="smallScreenImageLarge" style={{ position: 'relative' }}>
                    <Avatar src={circle} alt="Circle" variant='square' style={{
                        width: '90%', height: 'auto',
                        borderRadius:'50%',
                        display: { xs: 'none', md: 'block' }
                    }} />
                  

                </Grid>
                
                 <Grid item xs={12} md={5} >
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography className='poppinsSemiRegularBold' id="header" variant='h1' style={{ color: 'white', marginBottom: '10px', width: '100%' }}>
                                {productsHeaderHero}
                            </Typography>
                            <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                                {productsParaHero}
                            </Typography>
                        </Grid>


                    </Grid>


                </Grid>
             
                <Grid item xs={12} md={5} id="smallScreenImage"  style={{ position: 'relative' }}>
                    <Avatar
                        variant='square'
                        src={circle} alt="Circle" style={{
                            width: '100%', height: 'auto',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    {/* <Avatar variant='square' src={DummyImage} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: 'auto',
                            top: "10%",
                            left: '45%',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={meet} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            bottom: "3%",
                            right: '45%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={zoom} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            top: "40%",
                            left: '20%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={teams} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            top: "2%",
                            left: '30%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} /> */}

                </Grid>
                <Grid item xs={12} md={1} id="smallScreenImageLarge">
                </Grid>
              
               
            </Grid>
        </>
    );
}

export default HeroProduct;