import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { post } from '../urls/api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from './PaymentForm';
import { salt, stripePrimaryKey } from '../urls/apikeys';
import CryptoJS from 'crypto-js';
// Enable the skeleton loader UI for the optimal loading experience.

const loader = 'auto';
const appearance = {
    theme: 'stripe'
};
const CheckoutPage = () => {
    const stripePromise = loadStripe(stripePrimaryKey)
    const { price_id } = useParams();
    const [email, setEmail] = useState("");
    const [user_id_d, setUser_id_d] = useState("");
const [statusScreen,setStatusScreen]=useState(null)
    const getUserAndStoreToLocal = async (email) => {
        const dataPost = {
            email: email
        }
        const apiData = await post('user/getUserByEmail', dataPost); // Specify the endpoint you want to call
        console.log("User Details")
        console.log(apiData)
        setUser_id_d(apiData.result.user_id)
        if (apiData.error) {
        } else {
            localStorage.setItem("@UserSession", JSON.stringify({ user: apiData.result }));
        }
    }
    const [nameData, setNameData] = useState("");
    useEffect(() => {
//         const hasWebHash = window.location.hash.includes("id");
//         if (hasWebHash) {
//             const hashValue = window.location.hash.split("=")[1];

//             console.log('email ', hashValue)
//             //   decode it 
//             const bytes = CryptoJS.AES.decrypt(hashValue, salt);
//             const decryptedURL = bytes.toString(CryptoJS.enc.Utf8);
//             console.log("bytes")
//             console.log(decryptedURL)
//             setEmail(decryptedURL);
//     const initialName = decryptedURL.split('@')[0] || "";
// setNameData(initialName);
// console.log(decryptedURL)
//             getUserAndStoreToLocal(decryptedURL)
//             const urlParams = new URLSearchParams(window.location.search);
//             const status = urlParams.get('status'); // "Upgrade", "Subscribed", or "Renew"
//             console.log("Status: ", status);
//             // Do something if the hash contains "web"
//             //   local storage store 

//         } else {
//             //   setHashed(false)
//             console.log('no email ')

//             // Do something else if the hash doesn't contain "web"
//         }
const hasWebHash = window.location.hash.includes("id");

if (hasWebHash) {
    // Step 2: Extract the hash value (ID, which is the encrypted email in this case)
    // Split by '?', this will remove the 'status' from the hash part
    const hashValue = window.location.hash.split("?")[0].split("=")[1]; // Extract ID from hash
    console.log('Encrypted email from hash (ID only): ', hashValue);

    try {
        // Step 3: Decrypt the encrypted hash value (ID)
        const bytes = CryptoJS.AES.decrypt(hashValue, salt); // Use the same 'saltKey' as used during encryption
        const decryptedURL = bytes.toString(CryptoJS.enc.Utf8); // Convert bytes to a string (UTF-8)

        if (decryptedURL) {
            console.log("Decrypted email: ", decryptedURL);

            // Store the decrypted email (if needed)
            setEmail(decryptedURL);

            // Extract the username (the part before '@' in the email)
            const initialName = decryptedURL.split('@')[0] || "";
            setNameData(initialName);

            // Optionally, fetch and store user data based on the decrypted email
            getUserAndStoreToLocal(decryptedURL);
        } else {
            console.error("Failed to decrypt the hash value.");
        }
    } catch (error) {
        console.error("Error during decryption: ", error);
    }
    // Step 4: Now extract the 'status' parameter from the URL query string (after '?')
    const url = window.location.href;
    const statusParam = url.split('?')[1]?.split('&').find(param => param.startsWith('status='));
    
    if (statusParam) {
        const status = statusParam.split('=')[1]; // "Upgrade", "Subscribed", or "Renew"
        console.log("Status from query: ", status);
    
        if (status) {
            console.log("Status is:", status);
            setStatusScreen(status); // Set the status screen with the status value
        }
    }
}

    }, []);
    return (
        <>
                    {/* <PaymentForm priceId={price_id} EmailAddress={email}  /> */}

            <Elements stripe={stripePromise} options={{ appearance, loader }}>
                <div className="row">
                    <PaymentForm priceId={price_id} EmailAddress={email} user_id_d={user_id_d} statusScreen={statusScreen}/>
                </div>
            </Elements>
        </>
    )
}

export default CheckoutPage;