import { Avatar, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import circle from "../../assets/heroImage.png"
import DummyImage from "../../assets/imageDummy3.jpg"
import meet from "../../assets/meet.png"
import teams from "../../assets/teams.png"
import zoom from "../../assets/zoom.png"
import './stylesheet.css'
import { useNavigate } from 'react-router-dom';
import { heroParagraph, heroheaderPart1, heroheaderPart2 } from '../../urls/contactusKeys';





function HeroSection() {
    const navigate=useNavigate()
    return (
        <>

            <Grid container spacing={2}
            id="smallScreenHero"
                style={{
                    // background: 'rgb(255,255,255)',
                    backgroundColor:"#6a34d4",
                    // background: 'linear-gradient(126deg, rgba(255,255,255,1) 34%, rgba(189,243,254,0.3029586834733894) 100%)',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop:30


                }}>
                    <Grid item xs={12} id="smallScreenImage" md={5} style={{ position: 'relative'}}>
                    <Avatar
                    variant='square'
                     src={circle} alt="Circle" style={{
                        width: '100%', height: 'auto',
                        display: { xs: 'none', md: 'block' }
                    }} />
                </Grid>
                <Grid item xs={12} md={2} id="smallScreenImageLarge">
                </Grid>
                <Grid item xs={12} md={4} >
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography className='poppinsSemiRegularBold' id="header" variant='h1' style={{ color: 'white', marginBottom: '10px', width: '100%' ,fontSize:"70px"}}>
                            {heroheaderPart1}<br />{heroheaderPart2}
                            </Typography>
                            <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                               {heroParagraph}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} mt={4}>
                            {/* <ContainedButton text="Explore Products" onClick={()=>navigate('/products')} /> */}
                                <Button
                                    className='poppinsSemiRegularBold'
                                    // id="containedButton"
                                    onClick={()=>navigate('/products')}
                                    sx={{
                                        // width: '250px',
                                        backgroundColor: 'white',
                                        color: '#6a34d4',
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        padding: '10px 20px',
                                        borderRadius: '10px',
                                        textTransform: 'none',
                                        // bgcolor: "#007aff",
                                        // borderRadius: '50px',
                                        // fontSize: '19px',
                                        transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                        '&:hover': {
                                            bgcolor: '#6a34d4',
                                            color: 'white',
                                            border: '1px solid white', // Change text color to blue on hover
                                            transitionDelay: '0.2s', // Add transition delay for hover
                                       
                                        },
                                    }}
                                >Explore Products</Button>
                            {/* <OutlinedButton text="Start free Trail"/> */}

                        </Grid>

                    </Grid>


                </Grid>
                <Grid item xs={12} md={5} mt={-5}  id="smallScreenImageLarge"  style={{ position: 'relative'}}>
                    <Avatar src={circle} alt="Circle" variant='square' style={{
                        width: '100%', height: 'auto',
                        display: { xs: 'none', md: 'block' }
                    }} />
                    {/* <Avatar variant='square' src={DummyImage} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: 'auto',
                            top: "10%",
                            left: '45%',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={meet} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            bottom: "3%",
                            right: '45%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={zoom} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            top: "40%",
                            left: '20%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} />
                    <Avatar variant='square' src={teams} alt="Hero Image"
                        style={{
                            position: 'absolute',
                            width: '40px',
                            backgroundColor: 'white',
                            padding: '30px',
                            borderRadius: '50%',
                            height: 'auto',
                            top: "2%",
                            left: '30%',
                            border: '1px solid lightGray',
                            display: { xs: 'none', md: 'block' }
                        }} /> */}

                </Grid>
                <Grid item xs={12} md={1}>
                </Grid>
            </Grid>
        </>
    );
}

export default HeroSection;