import { Avatar, Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import circle from "../../assets/AAAA.png"
import './stylesheet.css'
import { useNavigate } from 'react-router-dom';
import { aboutUsHead, aboutUsHeader, aboutUsPara } from '../../urls/contactusKeys';

function HeroSection2() {
    const navigate=useNavigate()
    return (
        <>

            <Grid container spacing={2}
                id="smallScreenHero1"
                style={{
                    paddingBlock: '4%',
                    paddingBottom: '100px',
                    backgroundColor: 'white',
                    // height: '100vh',
                    display: 'flex',
                    alignItems: 'center',


                }}>
                <Grid item xs={12} md={1} style={{ position: 'relative' }}>
                </Grid>
                <Grid item xs={12} md={4} style={{ position: 'relative' }}>
                    <Avatar
                        variant='square'
                        src={circle} id="ImageHide" alt="Circle" style={{
                            height: 'auto',
                            // boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',

                        }} />
                </Grid>
                <Grid item xs={12} md={2} style={{ position: 'relative' }}>
                </Grid>

                <Grid item xs={12} md={4} >
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {/* <Typography className='poppinsSemiRegularBold' id="header1" variant='h1' style={{ color: '#041d34', marginBottom: '10px', width: '100%' }}>
                            Unleash the Power of Technology
                            </Typography> */}
                            {/* <Badge badgeContent="About us" color="primary" size="large" /> */}
                            <Box style={{ fontSize: '16px', width: '100px', textAlign: 'center', color: 'white', borderRadius: '20px' }}>
                                <Typography className="poppinsRegularBold" style={{ color: 'rgb(18 35 114)', fontWeight: 700 }}>
                                    {aboutUsHead}
                                </Typography>

                            </Box>
                            <Typography className='poppinsSemiRegularBold' variant='h3' style={{ color: '#6a34d4', marginBlock: '2%' }}>
                                {aboutUsHeader}
                            </Typography>
                            <Typography className='poppinsSemiRegularBold' variant='h5' style={{ color: '#041d34', marginBlock: '2%' }}>
                            - Empowering Innovation
                            </Typography>
                            <Typography variant='h6' id="subheader1" className='poppinsLight' style={{ color: '#041d34', fontweight: 700 }}>
                               {aboutUsPara}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} mt={4}>
                            <ContainedButton text="Explore More" onClick={()=>navigate('/products')} />

                        </Grid>

                    </Grid>


                </Grid>
                {/* <Grid item xs={12} md={1}>
                </Grid> */}
            </Grid>
        </>
    );
}

export default HeroSection2;