import {
    Box,
    Button,
    Card,
    ToggleButtonGroup,
    ToggleButton,
    Tabs,
    Tab,
    CardContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemText,
    Typography,
    Switch,
    FormControlLabel,
    Badge,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import "../../App.css"
import '../Hero/stylesheet.css'
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import { loginPage, salt } from '../../urls/apikeys';
import CryptoJS from 'crypto-js';
import { styled } from '@mui/material/styles';

// Tabs 
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//   end 
// switch 
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
// end   

function PricingSection({ features, pricing, pricingId }) {
    // tabs 
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // end 
    // toggle 
    const [alignment, setAlignment] = React.useState('MONTH');

    // const handleChange = (event, newAlignment) => {
    //     console.log(newAlignment)
    //     setAlignment(newAlignment);
    // };
    // switch 
    //   end 
    const navigate = useNavigate()
    // Tabs 

    React.useEffect
        (() => {
            console.log("pricingId")

            console.log(pricingId)
        }
            , [])
    return (
        <>
            <Grid container spacing={2} pt={8} style={{ backgroundColor: 'white' }}>
                {/* <Grid item xs={12} md={1}>
                </Grid> */}
                <Grid item xs={12} md={12}>
                    {/* <stripe-pricing-table pricing-table-id="prctbl_1O5QTEG6E8HShDISJu52zpPj"
                        publishable-key="pk_test_51M4MJTG6E8HShDISFyEI14H2x6tH8TH21B0sDWluCQ4Iv2ljQYn0gppIts9JO3FLiJfZzzwtVtu083g62siPwNuJ007grrrCos"
                        client-reference-id="rim_sha"
                    >
                    </stripe-pricing-table> */}

                    <Grid container spacing={2} style={{ padding: '3%' }}>
                        <Grid item xs={12} md={12} align="center">

                            <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h2' style={{ color: '#041d34', marginBottom: '20px', width: '100%' }}>
                                Our Plans and Pricing
                            </Typography>

                        </Grid>
                        <Grid item xs={12} md={12} align="center" style={{ marginBottom: '2%' }}>
                            {/* <ToggleButtonGroup style={{ borderRadius: '50%' }}
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton onClick={
                                    () => setAlignment("month")
                                } className='poppinsSemiRegularBold' style={{ textTransform: 'capitalize', fontWeight: 600 }} value="month">Monthly</ToggleButton>
                                <ToggleButton onClick={
                                    () => setAlignment("year")
                                } className='poppinsSemiRegularBold' style={{ textTransform: 'capitalize', fontWeight: 600 }} value="year">Yearly

                                    (15% off)
                                </ToggleButton>
                            </ToggleButtonGroup> */}
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h6' style={{ fontSize: '16px', marginRight: '1%' }}>
                                    Monthly
                                </Typography>
                                {/* on Change on this switch it will setAlignment state also  */}

                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} onChange={(event) => setAlignment(event.target.checked ? "YEAR" : "MONTH")} checked={alignment === "YEAR" ? true : false} />}
                                    label="Yearly"
                                />
                              <span
                              style={{
                                    color: 'white',
                                    fontSize: '13px',
                                    fontWeight: 600,
                                    backgroundColor:'#6a34d4',
                                    borderRadius:'10px',
                                    padding:'0.5%',
                              }}
                              >( 15 % off )</span>
                            </Box>

                            {/* <Tabs value={value} onChange={handleChange} 
                            aria-label="basic tabs example" 
                            style={{border:'1px solid lightGray',borderRadius:'50px',width:'12%'}}>
                                <Tab label="Monthly"  sx={{ textTransform:'capitalize','&.Mui-selected': { backgroundColor: '#007aff', color: '#fff',borderRadius:'20px' } }}  {...a11yProps(0)} />
                                <Tab label="Yearly" sx={{textTransform:'capitalize', '&.Mui-selected': { backgroundColor: '#007aff', color: '#fff',borderRadius:'20px' } }} {...a11yProps(1)} />
                            </Tabs> */}


                        </Grid>
                        {/* {pricing.length === 0 ? <>
                        </> :
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={5}>
                                    <Grid item xs={1} md={1} lg={1}></Grid>

                                    {pricing.map
                                        ((price, index) => (<>

                                            <Grid item xs={12} md={3} align="center">
                                                {price.type === alignment ? <>
                                                    <Card

                                                        sx={{
                                                            border: index === 1 || index === 4 ? '3px solid #007aff' : 'none',
                                                            transform: index === 1 || index === 4 ? 'scale(1.02)' : 'none',
                                                            // maxWidth: 345,
                                                            boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                                                            borderRadius: '20px',
                                                            cursor: 'pointer',
                                                            transition: 'transform 0.3s', // Add transition for zoom effect
                                                            '&:hover': {
                                                                transform: index === 1 || index === 4 ? 'scale(1.10)' : 'scale(1.02)' // Zoom effect on hover
                                                            }
                                                        }}
                                                    >

                                                        <CardContent style={{ textAlign: 'center' }}>
                                                            <div
                                                            // onClick={() => navigate(`/product_detail/`)}
                                                            >
                                                                <Typography className="poppinsSemiRegularBold"
                                                                    style={{
                                                                        fontSize: '20px',
                                                                        color: '#007aff',
                                                                        whiteSpace: 'nowrap', // Prevent text from wrapping
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis', // Show ellipsis when the text overflows
                                                                    }}
                                                                >
                                                                    {price.package_name}
                                                                </Typography>

                                                                <Typography className="poppinsRegularBold" style={{ fontSize: '64px', fontWeight: 600, marginTop: '10px' }}>
                                                                    ${price.package_price}

                                                                </Typography>

                                                                <Typography className="poppinsRegularBold" style={{ fontSize: '14px', textTransform: 'capitalize', fontWeight: 600, lineHeight: 2 }}>
                                                                    Per {price.type}

                                                                </Typography>
                                                                <Box style={{ padding: '2%', marginBlock: '6%' }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            const userSession = JSON.parse(localStorage.getItem("@UserSession"));
                                                                            if (userSession === null || userSession === undefined) {
                                                                                //open login Page 
                                                                                const redirectUrl = `${window.location.origin}/checkout_page/${price.package_id}`;
                                                                                // const hashedURL = SHA256(redirectUrl + salt).toString();
                                                                                const hashedURL = CryptoJS.AES.encrypt(redirectUrl, salt).toString();
                                                                                // setEncryptedURL(ciphertext);

                                                                                console.log(redirectUrl)
                                                                                window.open(`${loginPage}${hashedURL}`)
                                                                                console.log("AAAA")


                                                                            }
                                                                            else {
                                                                                console.log("sdgdf")
                                                                                const emailData = userSession.user.email
                                                                                const hashedEmail = CryptoJS.AES.encrypt(emailData, salt).toString();

                                                                                navigate(`/checkout_page/${price.package_id}/#id=${hashedEmail}`)

                                                                            }

                                                                        }
                                                                        }
                                                                        className='poppinsRegularBold'
                                                                        sx={{
                                                                            // width: '100px',
                                                                            paddingInline: '20px',
                                                                            color: index === 1 || index === 4 ? "white" : "#007aff",
                                                                            backgroundColor: index === 1 || index === 4 ? "#007aff" : "none",
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            borderRadius: '50px',
                                                                            textTransform: 'capitalize',
                                                                            transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                                                            transitionDelay: '0.3s', // Add transition delay for hover
                                                                            border: '1px solid #007aff',
                                                                            '&:hover': {
                                                                                backgroundColor: index === 1 || index === 4 ? 'white' : "#007aff",
                                                                                color: index === 1 || index === 4 ? '#007aff' : 'white', // Change text color to blue on hover
                                                                            },
                                                                        }} >Buy now</Button>
                                                                    <Divider style={{ marginTop: '10%', marginBottom: '2%' }} />
                                                                    <List>
                                                                        {price?.feature?.length === 0 || price?.feature === null ? <>
                                                                        </> :
                                                                            <>
                                                                                {price?.feature?.map
                                                                                    ((item) => (
                                                                                        <ListItem key="1" disablePadding>
                                                                                            <ListItemButton style={{ padding: '5px' }}>
                                                                                                <ListItemIcon>
                                                                                                    <DoneIcon style={{ color: 'green' }} />
                                                                                                </ListItemIcon>
                                                                                                <ListItemText primary={item.featureD} />
                                                                                            </ListItemButton>
                                                                                        </ListItem>
                                                                                    ))
                                                                                }
                                                                            </>}
                                                                    </List>
                                                                </Box>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </> :
                                                    null}
                                            </Grid>
                                            {index === 2 ? <>
                                                <Grid item xs={1} md={1} lg={1}></Grid>
                                            </> :
                                                <>
                                                </>
                                            }

                                        </>
                                        ))}
                                </Grid>
                            </Grid>

                        } */}
                        {window.innerWidth<730?
                        <Grid container spacing={2}>
                        {pricing.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <Grid item xs={12} md={12}>
                              {/* <Grid container spacing={1}> */}
                                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
                      <div style={{display:"flex",justifyContent:"center",alignItems:'center',flexDirection:"column"}}>
                      
                      
                                {pricing.map((price, index) => (
                                  <React.Fragment key={index}>
                                {/* <Grid item xs={12} md={3} align="center"> */}
                                 {price.type === alignment ? ( 
                                        <Card
                                          sx={{
                                              width:"90%",
                                              margin:"2%",
                                            border: index === 1 || index === 4 ? '3px solid #6a34d4' : 'none',
                                            transform: index === 1 || index === 4 ? 'scale(1.02)' : 'none',
                                            boxShadow: '0px 2px 30px -15px rgba(94,94,107,0.67)',
                                            borderRadius: '20px',
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s',
                                            '&:hover': {
                                              transform: index === 1 || index === 4 ? 'scale(1.10)' : 'scale(1.02)',
                                            },
                                          }}
                                        >
                                          <CardContent style={{ textAlign: 'center' }}>
                                            <Typography
                                              className="poppinsSemiRegularBold"
                                              sx={{
                                                fontSize: '20px',
                                                color: '#6a34d4',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {price.package_name}
                                            </Typography>
                      
                                            <Typography
                                              className="poppinsRegularBold"
                                              sx={{
                                                fontSize: '64px',
                                                fontWeight: 600,
                                                marginTop: '10px',
                                              }}
                                            >
                                              ${price.package_price}
                                            </Typography>
                      
                                            <Typography
                                              className="poppinsRegularBold"
                                              sx={{
                                                fontSize: '14px',
                                                textTransform: 'capitalize',
                                                fontWeight: 600,
                                                lineHeight: 2,
                                              }}
                                            >
                                              Per {price.type}
                                            </Typography>
                      
                                            <Box sx={{ padding: '2%', marginBlock: '6%' }}>
                                              <Button
                                                onClick={() => {
                                                  const userSession = JSON.parse(localStorage.getItem('@UserSession'));
                                                  if (!userSession) {
                                                    const redirectUrl = `${window.location.origin}/checkout_page/${price.package_id}`;
                                                    const hashedURL = CryptoJS.AES.encrypt(redirectUrl, salt).toString();
                                                    window.open(`${loginPage}${hashedURL}`);
                                                  } else {
                                                    const emailData = userSession.user.email;
                                                    const hashedEmail = CryptoJS.AES.encrypt(emailData, salt).toString();
                                                    navigate(`/checkout_page/${price.package_id}/#id=${hashedEmail}`);
                                                  }
                                                }}
                                                className="poppinsRegularBold"
                                                sx={{
                                                  paddingInline: '20px',
                                                  color: index === 1 || index === 4 ? 'white' : '#6a34d4',
                                                  backgroundColor: index === 1 || index === 4 ? '#6a34d4' : 'none',
                                                  fontSize: '16px',
                                                  fontWeight: 600,
                                                  borderRadius: '50px',
                                                  textTransform: 'capitalize',
                                                  transition: 'background-color 0.3s, color 0.3s',
                                                  transitionDelay: '0.3s',
                                                  border: '1px solid #6a34d4',
                                                  '&:hover': {
                                                    backgroundColor: index === 1 || index === 4 ? 'white' : '#6a34d4',
                                                    color: index === 1 || index === 4 ? '#6a34d4' : 'white',
                                                  },
                                                }}
                                              >
                                                Buy now
                                              </Button>
                                              <Divider sx={{ marginTop: '10%', marginBottom: '2%' }} />
                                              <List>
                                                {price?.feature?.length > 0 &&
                                                  price?.feature.map((item, itemIndex) => (
                                                    <ListItem key={itemIndex} disablePadding>
                                                      <ListItemButton sx={{ padding: '5px' }}>
                                                        <ListItemIcon>
                                                          <DoneIcon sx={{ color: 'green' }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.featureD} />
                                                      </ListItemButton>
                                                    </ListItem>
                                                  ))}
                                              </List>
                                            </Box>
                                          </CardContent>
                                        </Card>
                                      ) : null}  
                                       {/* </Grid> */}
                                    
                               
                      
                                    {/* {index === 2 && (
                                      <Grid item xs={1} md={1} lg={1}></Grid>
                                    )} */}
                                  </React.Fragment>
                                ))}
                                </div>
                              </Grid>
                            {/* </Grid> */}
                          </>
                        )}
                      </Grid>:
                      
                        <Grid container spacing={5}>
  {pricing.length === 0 ? (
    <></>
  ) : (
    <>
      <Grid item xs={12} md={12}>
        {/* <Grid container spacing={1}> */}
          {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
<div style={{display:"flex",justifyContent:"space-around",alignItems:'center'}}>


          {pricing.map((price, index) => (
            <React.Fragment key={index}>
          {/* <Grid item xs={12} md={3} align="center"> */}
           {price.type === alignment ? ( 
                  <Card
                    sx={{
                        width:"50%",
                        margin:"2%",
                      border: index === 1 || index === 4 ? '3px solid #6a34d4' : 'none',
                      transform: index === 1 || index === 4 ? 'scale(1.02)' : 'none',
                      boxShadow: '0px 2px 30px -15px rgba(94,94,107,0.67)',
                      borderRadius: '20px',
                      cursor: 'pointer',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: index === 1 || index === 4 ? 'scale(1.10)' : 'scale(1.02)',
                      },
                    }}
                  >
                    <CardContent style={{ textAlign: 'center' }}>
                      <Typography
                        className="poppinsSemiRegularBold"
                        sx={{
                          fontSize: '20px',
                          color: '#6a34d4',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {price.package_name}
                      </Typography>

                      <Typography
                        className="poppinsRegularBold"
                        sx={{
                          fontSize: '64px',
                          fontWeight: 600,
                          marginTop: '10px',
                        }}
                      >
                        ${price.package_price}
                      </Typography>

                      <Typography
                        className="poppinsRegularBold"
                        sx={{
                          fontSize: '14px',
                          textTransform: 'capitalize',
                          fontWeight: 600,
                          lineHeight: 2,
                        }}
                      >
                        Per {price.type}
                      </Typography>

                      <Box sx={{ padding: '2%', marginBlock: '6%' }}>
                        <Button
                          onClick={() => {
                            const userSession = JSON.parse(localStorage.getItem('@UserSession'));
                            if (!userSession) {
                              const redirectUrl = `${window.location.origin}/checkout_page/${price.package_id}`;
                              const hashedURL = CryptoJS.AES.encrypt(redirectUrl, salt).toString();
                              window.open(`${loginPage}${hashedURL}`);
                            } else {
                              const emailData = userSession.user.email;
                              const hashedEmail = CryptoJS.AES.encrypt(emailData, salt).toString();
                              navigate(`/checkout_page/${price.package_id}/#id=${hashedEmail}`);
                            }
                          }}
                          className="poppinsRegularBold"
                          sx={{
                            paddingInline: '20px',
                            color: index === 1 || index === 4 ? 'white' : '#6a34d4',
                            backgroundColor: index === 1 || index === 4 ? '#6a34d4' : 'none',
                            fontSize: '16px',
                            fontWeight: 600,
                            borderRadius: '50px',
                            textTransform: 'capitalize',
                            transition: 'background-color 0.3s, color 0.3s',
                            transitionDelay: '0.3s',
                            border: '1px solid #6a34d4',
                            '&:hover': {
                              backgroundColor: index === 1 || index === 4 ? 'white' : '#6a34d4',
                              color: index === 1 || index === 4 ? '#6a34d4' : 'white',
                            },
                          }}
                        >
                          Buy now
                        </Button>
                        <Divider sx={{ marginTop: '10%', marginBottom: '2%' }} />
                        <List>
                          {price?.feature?.length > 0 &&
                            price?.feature.map((item, itemIndex) => (
                              <ListItem key={itemIndex} disablePadding>
                                <ListItemButton sx={{ padding: '5px' }}>
                                  <ListItemIcon>
                                    <DoneIcon sx={{ color: 'green' }} />
                                  </ListItemIcon>
                                  <ListItemText primary={item.featureD} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </List>
                      </Box>
                    </CardContent>
                  </Card>
                ) : null}  
                 {/* </Grid> */}
              
         

              {/* {index === 2 && (
                <Grid item xs={1} md={1} lg={1}></Grid>
              )} */}
            </React.Fragment>
          ))}</div>
        </Grid>
      {/* </Grid> */}
    </>
  )}
</Grid>}

                    </Grid>
                </Grid>

            </Grid>


        </>
    );
}

export default PricingSection;