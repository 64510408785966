import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"

function OutlinedButton({text}) {
    return (
        <>

            <Button
                className='poppinsRegularBold'
                sx={{
                    width: '100%',
                    // display: { xs: 'none', md: 'block' },
                    color: '#007aff',
                    fontSize: '16px',
                    borderRadius: '50px',
                    border:'1px solid #007aff',
                    marginLeft: '10px',

                    textTransform: 'capitalize',
                  transition: 'background-color 0.3s, color 0.3s', // Add transition properties

                    '&:hover': {
                        bgcolor: "#007aff",
                        color: 'white', 
                    transitionDelay: '0.3s', // Add transition delay for hover

                    },

                }} >{text}</Button>
        </>
    );
}

export default OutlinedButton;