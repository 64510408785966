
export const stripePrimaryKey = process.env.REACT_APP_PUBLISH_KEY
// login pages process.env.REACT_APP_API_URL;
// const urlUserPanel="http://localhost:3001"
const urlUserPanel="https://portal.fyniz.com"
export const loginPage = `${urlUserPanel}/login/#web=`; // Login Page
export const signupPage = `${urlUserPanel}/register/web`; // Signup Page
export const loginPageUnLoggedUser = `${urlUserPanel}/login/#web=`; // Login Page
export const signupPageUnLoggedUser = `${urlUserPanel}/register/#web=`; // Register Page
export const subscribed_products = `${urlUserPanel}/subscribed_product_detail`; // Register Page

export const salt = "mySalt";

