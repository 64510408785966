import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import * as React from "react";
import "../../App.css";
import ContainedButton from "../Buttons/ContainedButton";
import imageDummy from "../../assets/imageDummy.jpg";
import imageDummy1 from "../../assets/imageDummy2.jpg";
import imageDummy2 from "../../assets/imageDummy3.jpg";
import arrow from "../../assets/arrow.png";
import arrow1 from "../../assets/arrow1.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  testimonialHeaderPart1,
  testimonialHeaderPart2,
} from "../../urls/contactusKeys";

function Testimonial({ margins, paddings }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Box>
        <Grid
          id="testimonialHeader"
          container
          spacing={2}
          mt={margins}
          pt={paddings}
          style={{ backgroundColor: "white" }}
        >
          {/* <Grid item xs={12} md={2}>
                            </Grid> */}
          <Grid
            item
            xs={12}
            md={12}
            align="center"
            id="testimonials"
            // style={{ padding: '60px' }}
          >
            <Box
              id="cardHeight"
              style={{
                backgroundColor: `#6a34d4`,
                height: "auto",
                borderRadius: "30px",
                textAlign: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  align="center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "5%",
                    paddingTop: "10%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="poppinsSemiRegularBold"
                    id="sectionTestimonial"
                    variant="h1"
                    style={{ width: "100%" }}
                  >
                    {testimonialHeaderPart1} <br />
                    {testimonialHeaderPart2}
                  </Typography>
                  <span style={{ marginTop: "3%" }}>
                    {/* <ContainedButton text="Explore Products" onClick={()=>navigate('/products')} /> */}
                    <Button
                      className="poppinsSemiRegularBold"
                      // id="containedButton"
                      onClick={() => {
                        if (location.pathname === "/products") {
                          // If already on the /products page, just scroll to top
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                          // Navigate to /products
                          navigate("/products");
                        }
                      }}
                      sx={{
                        // width: '250px',
                        backgroundColor: "white",
                        color: "#6a34d4",
                        fontWeight: 700,
                        fontSize: "20px",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        textTransform: "none",
                        // bgcolor: "#007aff",
                        // borderRadius: '50px',
                        // fontSize: '19px',
                        transition: "background-color 0.3s, color 0.3s", // Add transition properties
                        "&:hover": {
                          bgcolor: "#6a34d4",
                          color: "white",
                          border: "1px solid white", // Change text color to blue on hover
                          transitionDelay: "0.2s", // Add transition delay for hover
                        },
                      }}
                    >
                      Explore Products
                    </Button>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  align="center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "5%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    src={arrow}
                    alt="arrow"
                    variant="square"
                    style={{
                      width: "50%",
                      height: "auto",
                      opacity: 0.5,
                      // display: { xs: 'none', md: 'block' }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Testimonial;
