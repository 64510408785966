// import logo from './logo.svg';
import * as React from 'react';
import './App.css';

import Products from './pages/Products';
import toast, { Toaster } from 'react-hot-toast';

import { Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import ProductDetails from './pages/ProductDetails';
import CheckoutPage from './pages/CheckoutPage';
import ErrorPage from './pages/ErrorPage';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/Privacy';
import Terms from './pages/Terms';
import SuccessPagePayment from './pages/SuccessPagePayment';
import ErrorPagePayment from './pages/ErrorPagePayment';

function App() {


  const firstSectionRef = React.useRef(null);

  React.useEffect(() => {
    // When the component mounts, scroll to the first section
    if (firstSectionRef.current) {
      firstSectionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts
  

  // fuctiom to wrir api call
  // const getProducts = async () => {
  //   const response = await fetch('https://fakestoreapi.com/products');
  //   const data = await response.json();
  //   console.log(data);
  // }
  


  return (
    <>
      <Routes >
        <Route path={`/`} element={<HomePage />} />
        <Route path={`/product_detail/:id`} element={<ProductDetails />} />
        <Route path={`/products`} element={<Products />} />
        <Route path={`/return`} element={<SuccessPagePayment />} />
        <Route path={`/cancel`} element={<ErrorPagePayment />} />


        <Route path={`/checkout_page/:price_id`} element={<CheckoutPage />} />
        <Route path={`/contact_us`} element={<ContactUs />} />
        <Route path={`/about_us`} element={<AboutUs />} />
        <Route path={`/privacy_policy`} element={<PrivacyPolicy />} />
        <Route path={`/terms`} element={<Terms />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <div>
        <Toaster
            position="top-center"
        />
    </div>
    </>
  );
}

export default App;
