// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';
import { Grid, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import InternetError from "../assets/errorPage.png"
// import ClipLoader from "react-spinners/ClipLoader";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
    const color = "rgb(150, 143, 143)"
    const override = {
        display: ' block',
        margin: '0 auto',
    }
    const [disabled, setdisabled] = React.useState(false)
    const navigate = useNavigate()


    return (
        <>
            < Grid container spacing={2}>
                <Grid item xs={12} md={3} align="center">
                </Grid>
                <Grid item xs={12} md={6}  align="center" style={{height:'90vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <Avatar src={InternetError} variant="square" style={{ width: '70%', height: 'auto' }} ></Avatar>
                   <Typography variant="h3" fontWeight={700}  style={{  marginBlock:'1%'}}  className='poppinsRegularBold'>
                        404 Page Not Found
                    </Typography> 
                    <Typography variant="h6" fontWeight={500} style={{  marginBlock:'2%'}}  className='poppinsRegularBold'>
                        THE PAGE DOESN'T EXIST OR IS UNAVAILABLE.
                    </Typography>
                  
                     <Button onClick={()=>navigate('/')}
                className='poppinsRegularBold'
                sx={{
                  width: '100px',
                  marginBlock:'4%',
                  color: 'white',
                  bgcolor: "#007aff",
                  borderRadius: '50px',
                  fontSize: '16px',
                  transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                  '&:hover': {
                    bgcolor: "white",
                    color: '#007aff',
                    border: '1px solid #007aff', // Change text color to blue on hover
                    transitionDelay: '0.3s', // Add transition delay for hover
                  },
                  marginLeft: '10px',
                  textTransform: 'capitalize'

                }}>Login</Button>
                </Grid>
                <Grid item xs={12} md={3} align="center">
                </Grid>
              
            </Grid>

        </>
    );
}

export default ErrorPage;
