import React, { useEffect, useState } from "react";
// import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements, CardElement, ExpressCheckoutElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, get, post } from "../urls/api";
import logo from "../assets/logo.png";

import {
  AppBar,
  Avatar,
  ListItemText,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Skeleton,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StarIcon from "@mui/icons-material/Star";
import { globalColor } from "../utils/globalColor";
import DoneIcon from "@mui/icons-material/Done";
import cardImages from "../assets/CardImages.png";
import cardImage2 from "../assets/cardImage2.png";
import { ClipLoader } from "react-spinners";
import toastAlert from "../components/ToastAlert";
import { subscribed_products } from "../urls/apikeys";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const override = {
  display: "block",
  margin: "0 auto",
};
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      width: "100%",
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: 500,
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "black" },
      "::placeholder": { color: "lightgray" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "black",
    },
  },
};

export default function PaymentForm({ priceId, EmailAddress,user_id_d,statusScreen }) {
  const [nameData, setNameData] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingStripe, setloadingStripe] = useState(false);
  const [result, showresult] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(true);

  const Result = () => {
    return (
      <>
        <Typography
          variant="h6"
          className="poppinsRegularBold"
          style={{ fontSize: "16px", color: "red" }}
        >
          {errorMsg}
        </Typography>
        {/* // <Alert variant="error" className="error-msg">
            //     {errorMsg}
            // </Alert>  */}
      </>
    );
  };

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  // const elements =  stripe.elements({clientSecret, appearance});
  const handleSubmit = async (e) => {
    setloadingStripe(true);
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      setloadingStripe(false);
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // const paymentMethod = await stripe.paymentMethods.create({
    //     type: 'card',
    //     card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement)
    //   });
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardCvcElement,
        CardExpiryElement,
        CardNumberElement
      ),
    });
    console.log(paymentMethod);
    // console.log(error)
    if (error) {
      console.log("error.message");
      console.log(error);
      if (error.type === "card_error") {
        console.log("Card declined:", error.message);
        setloadingStripe(false);
        // You can show an error message to the user here.
      } else {
        console.log("Error:", error.message);
      }
      setloadingStripe(false);
      showresult(true);
      // toastAlert("error", error.message)
      setErrorMsg(error.message);
    } else {
      try {
        const id = paymentMethod.id;
        console.log(paymentMethod.id);
        console.log("paymentMethod");
        console.log(paymentMethod);
        console.log(paymentMethod);
        const postData = {
          // amount: 10000,
          // paymentMethod: id,
          paymentMethodId: id,
          // user_email: email,
          customeremail: EmailAddress,
          user_id:user_id_d,
          // selected_priceId: "price_1NY1EuGui44lwdb4IX632ZW2",
          priceId: priceId,
          statusScreen:statusScreen
          // "user_Id": "100004"
        };
        const response = await post("subscription-stripe", postData);
        console.log(response);
        if (response.error) {
          // toastAlert("error", response.message)
          setloadingStripe(false);
          showresult(true);
          // toastAlert("error", error.message)
          setErrorMsg(response.message);
        } else {
          // toastAlert("success", response.message)
          // navigate('/dashboard/app')
          setloadingStripe(false);
          // setSuccess(true)
          // toastAlert("success", response.message)
          const subscriptionId = response.subscriptionObject.subscription_id;
          window.open(
            `${subscribed_products}/${subscriptionId}/#toast=subscription_success`,
            "_self"
          );
        }
      } catch (error) {
        console.log("sdfdfgdf");
        console.log("Error", error);
        setloadingStripe(false);
      }
    }
  };

  const [packageName, setPackageName] = useState("");
  const [productName, setProductName] = useState("");

  const [packageDescription, setPackageDescription] = useState("");
  const [packagePrice, setPrice] = useState("");
  const [type, setType] = useState("");
  const [features, setFeatures] = useState([]);
  const [product_image, setProductImage] = useState();

  const [priceIdPaypal, setPriceIdPaypal] = useState("");
  // end
  const fetchPricing = async () => {
    try {
      const postData = {
        price_id: priceId,
      };
      const apiData = await post("package/getPackageByPriceId", postData); // Specify the endpoint you want to call
      console.log(apiData);
      if (apiData.error) {
        console.log("error true");
        setLoader(false);
      } else {
        console.log(apiData.data);
        setPackageName(apiData?.data?.dbPrice?.package_name);
        setPackageDescription(apiData?.data?.product?.description);
        setPrice(apiData?.data?.dbPrice?.package_price);
        setType(apiData?.data?.dbPrice?.type);
        setFeatures(apiData?.data?.dbPrice?.feature);
        setProductImage(apiData?.data?.product?.image);
        setPriceIdPaypal(apiData?.data?.dbPrice?.stripe_price_id);
        setProductName(apiData?.data?.product?.product_name);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const successSubscription = async (subscriptionId) => {
    console.log("suvcess");
    const localMenuItem = localStorage.getItem("@UserSession");
    const user = JSON.parse(localMenuItem);
    console.log(user?.user?.user_id);
    const postData = {
      price_id: priceId,
    };
    const apiData = await post("package/getPackageByPriceId", postData); // Specify the endpoint you want to call
    console.log("apiData");
    console.log(apiData);
    const product_id_db = apiData?.data?.product?.product_id;
    console.log(product_id_db);
    const package_id_db = apiData?.data?.dbPrice?.package_id;
    const product_id_str = apiData?.data?.product?.product_id_stripe;
    const package_id_str = apiData?.data?.dbPrice?.stripe_price_id;

    const postData1 = {
      subscription_id: subscriptionId,
      user_id: user?.user?.user_id,
      price_id: priceId,
      email: user?.user?.email,
      planId: priceIdPaypal,
      package_id_db: package_id_db,
      product_id_db: product_id_db,
      package_id_str: package_id_str,
      product_id_str: product_id_str,

      // Replace with the actual user ID if needed
    };

    try {
      const apiData = await post("success-subscription", postData1);
      console.log("apiData");

      console.log(apiData);
      if (apiData.error) {
        setLoader(false);

        console.log("An error occurred during the subscription process.");
      } else {
        setLoader(false);

        console.log("Subscription successful.");
        setSuccess(true);
        const subscription_id = apiData?.subscriptionDB?.subscription_id;
        // change url above to just checkout_page
        setTimeout(() => {
          window.open(
            `${subscribed_products}/${subscription_id}/#toast=subscription_success`,
            "_self"
          );
        }, 3000);

        // Optionally, handle further actions on success
      }
    } catch (error) {
      setLoader(false);

      console.error("Error in successSubscription:", error);
    }
  };
  const callfunctionUrl = async () => {
    // Check if the URL has a subscription_id parameter and call the successSubscription function if present
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const subscriptionId = params.get("subscription_id");

    console.log("Subscription ID:", subscriptionId);
    if (subscriptionId) {
      await successSubscription(subscriptionId);
    } else {
      await fetchPricing();
    }
  };
  useEffect(() => {
    console.log("useEffect triggered");
    callfunctionUrl();
  }, []);

  return (
    <>
      {loader ? (
        <>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "5%" }}>
              <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "5%" }}>
              <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>
        </>
      ) : (
        <>
          <AppBar
            position="sticky"
            style={{
              backgroundColor: "#f5f8fa",
              boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
              borderBottom: "0.5px solid rgba(198,198,198,1)",
            }}
          >
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} md={6}>
                    <Avatar
                      sx={{
                        display: { xs: "block", md: "block" },
                        width: "150px",
                        height: "auto",
                        marginTop: "-10px",
                      }}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      alt="Logo"
                      src={logo}
                      variant="square"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>
          {!success ? (
            <Grid
              container
              spacing={2}
              style={{ backgroundColor: "#f7f7f7", height: "100%" }}
            >
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={8} style={{ paddingBlock: "5%" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    align="left"
                    style={{ padding: "2%" }}
                  >
                    <Card
                      sx={{
                        // maxWidth: 345,
                        boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "transform 0.3s", // Add transition for zoom effect
                        "&:hover": {
                          transform: "scale(1.02)", // Zoom effect on hover
                        },
                      }}
                    >
                      <Box
                        style={{
                          boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                          padding: "4%",
                        }}
                      >
                        <Box
                          style={{ padding: "20px" }}
                          // onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              border: "1px solid lightGray",
                              borderRadius: "10px",
                            }}
                            height="194"
                            image={`${BASE_URL}${product_image}`}
                            alt="Paella dish"
                          />
                        </Box>
                        <CardContent style={{ textAlign: "left" }}>
                          <div
                          // onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                          >
                            <Typography
                              className="poppinsSemiRegularBold"
                              style={{
                                fontSize: "20px",
                                color: "#6a34d4",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Show ellipsis when the text overflows
                                maxWidth: productName > 26 ? "16ch" : "none", // Limit the width to 16 characters if necessary
                              }}
                              title={
                                productName.length > 26 ? productName : null
                              } // Set a title for the full name
                            >
                              {productName}
                            </Typography>

                            <Typography
                              className="poppinsRegularBold"
                              style={{ fontSize: "15px", marginTop: "10px" }}
                            >
                              {packageDescription}
                            </Typography>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <StarIcon style={{ color: "orange" }} />
                                <Typography
                                  className="poppinsRegularBold"
                                  style={{ fontSize: "15px" }}
                                >
                                  (4.5)
                                </Typography>
                              </div>
                            </span>
                            <Divider
                              style={{ marginTop: "10%", marginBottom: "2%" }}
                            />
                            <List>
                              {features.length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  {features.map((item, index) => (
                                    <ListItem key={index} disablePadding>
                                      <ListItemButton
                                        style={{ padding: "5px" }}
                                      >
                                        <ListItemIcon>
                                          <DoneIcon
                                            style={{ color: "green" }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText primary={item.featureD} />
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                                </>
                              )}
                            </List>
                          </div>
                        </CardContent>
                        {/* <CardActions disableSpacing>
                                                       <IconButton aria-label="add to favorites">
                                                           <FavoriteIcon />
                                                       </IconButton>
                                                       <IconButton aria-label="share">
                                                           <ShareIcon />
                                                       </IconButton>
                                                     
                                                   </CardActions> */}
                      </Box>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ paddingLeft: "10%", padding: "3%" }}
                  >
                    <Card
                      sx={{
                        //    maxWidth: 345,
                        boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "transform 0.3s", // Add transition for zoom effect
                        "&:hover": {
                          transform: "scale(1.02)", // Zoom effect on hover
                        },
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                          padding: "5%",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ borderRadius: "5px", padding: "20px" }}
                        >
                          <Box style={{ display: "flex" }}>
                            <ShoppingCartIcon
                              style={{
                                marginTop: "5px",
                                marginRight: "10px",
                                color: `${globalColor}`,
                              }}
                            />
                            <Typography
                              variant="h5"
                              className="poppinsSemiRegularBold"
                            >
                              Order Summary
                            </Typography>
                          </Box>

                          <Box>
                            <Box
                              style={{
                                marginTop: "3%",
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBlock: "3%",
                              }}
                            >
                              <Typography
                                variant="h6"
                                className="poppinsRegularBold"
                                style={{ fontSize: "16px" }}
                              >
                                {productName}
                              </Typography>
                              <Typography
                                variant="h6"
                                className="poppinsRegularBold"
                                style={{ fontSize: "16px" }}
                              >
                                $ {packagePrice}
                              </Typography>
                            </Box>
                            <Typography
                              variant="h6"
                              className="poppinsLight"
                              style={{
                                fontSize: "16px",
                                color: "gray",
                                paddingBottom: "3%",
                              }}
                            >
                              {packageName}
                            </Typography>
                            {type === "MONTH" ? (
                              <Badge
                                // badgeContent={type}
                                style={{
                                  color: "white",
                                  backgroundColor:
                                    type === "month" ? globalColor : "green",
                                  marginLeft: "10px",
                                  padding: "5px",
                                  paddingInline: "10px",
                                  borderRadius: "30px",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                Monthly
                              </Badge>
                            ) : (
                              <Badge
                                // badgeContent={type}
                                style={{
                                  color: "white",
                                  backgroundColor:
                                    type === "year" ? globalColor : "#12AD2B",
                                  marginLeft: "10px",
                                  padding: "5px",
                                  paddingInline: "10px",
                                  borderRadius: "30px",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                Yearly ( saved 15% )
                              </Badge>
                            )}

                            {/* <Box style={{ marginTop: '3%', display: 'flex', justifyContent: 'space-between', paddingBlock: '3%' }}>
                                                <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '16px' }} >
                                                    Taxes & Fees
                                                </Typography>
                                                <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '16px' }} >
                                                    $ 5
                                                </Typography>
                                            </Box> */}
                          </Box>
                          <Divider
                            style={{
                              marginTop: "3%",
                              marginBottom: "3%",
                            }}
                          />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingBlock: "4%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              className="poppinsSemiRegularBold"
                              style={{ marginTop: "2%" }}
                            >
                              Total <span>(included taxes)</span>
                            </Typography>
                            <Typography
                              variant="h6"
                              className="poppinsSemiRegularBold"
                              style={{ marginTop: "2%" }}
                            >
                              $ {packagePrice}
                            </Typography>
                            {/* <Typography variant='h6' className='poppinsLight' style={{ fontSize: '16px', color: 'gray' }} >
                                                Sub total : <span style={{ fontWeight: 600 }}>
                                                    $ 10233
                                                </span>
                                            </Typography> */}
                          </Box>
                          {/* {!success ? */}
                          <form
                            className="axil-contact-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="form-group">
                              {result ? <Result /> : null}
                            </div>
                            {/* <Typography variant='h5' className='poppinsSemiRegularBold' >
                                        Billing Details
                                    </Typography>
                                    <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '16px', paddingBlock: '2%' }} >
                                        First & last name
                                    </Typography>
                                    <TextField variant="outlined" placeholder="John Doe" style={{ width: "100%" }} />

                                    <Typography variant='h6' className='poppinsRegularBold' style={{ fontSize: '16px', paddingBlock: '2%' }} >
                                        Email Address
                                    </Typography>
                                    <TextField type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control-stripe" name="contact-email" placeholder="example@mail.com" required style={{ width: "100%" }} />
                                    */}
                            <Typography
                              variant="h6"
                              className="poppinsSemiRegularBold"
                              style={{ marginTop: "2%" }}
                            >
                              Enter Payment Details
                            </Typography>
                            <Typography
                              variant="h6"
                              className="poppinsRegularBold"
                              style={{ fontSize: "16px", paddingBlock: "2%" }}
                            >
                              Email Address
                            </Typography>
                            <TextField
                              disabled
                              type="email"
                              value={EmailAddress}
                              className="form-control-stripe"
                              name="contact-email"
                              placeholder="example@mail.com"
                              required
                              style={{ width: "99%", marginBotttom: "20px" }}
                            />
                            {/* <TextField label="Name" type="text"
                                                         value={nameData} 
                                                         onChange={(e) => setNameData(e.target.value)}
                                                          className="form-control-stripe" name="contact-email" placeholder="John Doe" required style={{ width: "100%", marginBlock: '3%', borderRadius: '5px',fontWeight:700 }} />

                                                        <TextField label="Email" type="email" value={EmailAddress} disabled className="form-control-stripe" name="contact-email" placeholder="example@mail.com" required style={{ width: "100%", marginBlock: '3%', borderRadius: '5px' ,fontWeight:700}} /> */}

                            <fieldset
                              className="FormGroup"
                              style={{
                                marginBlock: "3%",
                                border: "1px solid lightGray",
                                borderRadius: "5px",
                              }}
                            >
                              <label className="poppinsRegularBold">
                                Card Number
                              </label>
                              <div
                                className="form-control-stripe"
                                style={{
                                  marginBlock: "2%",
                                }}
                              >
                                <CardNumberElement options={CARD_OPTIONS} />
                              </div>
                            </fieldset>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <fieldset
                                className="FormGroup"
                                style={{
                                  marginBlock: "3%",
                                  width: "40%",
                                  border: "1px solid lightGray",
                                  borderRadius: "5px",
                                }}
                              >
                                <label className="poppinsRegularBold">
                                  Card Expiry
                                </label>
                                <div
                                  className="form-control-stripe"
                                  style={{
                                    marginBlock: "2%",
                                  }}
                                >
                                  <CardExpiryElement options={CARD_OPTIONS} />
                                </div>
                              </fieldset>
                              <fieldset
                                className="FormGroup"
                                style={{
                                  marginBlock: "3%",
                                  width: "40%",
                                  border: "1px solid lightGray",
                                  borderRadius: "5px",
                                }}
                              >
                                <label className="poppinsRegularBold">
                                  Cvc
                                </label>
                                <div
                                  className="form-control-stripe"
                                  style={{
                                    marginBlock: "2%",
                                  }}
                                >
                                  <CardCvcElement options={CARD_OPTIONS} />
                                </div>
                              </fieldset>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              {/* end  */}
                              <Button
                                startIcon={
                                  loadingStripe ? (
                                    <ClipLoader
                                      color="white"
                                      loading={loadingStripe}
                                      cssOverride={override}
                                      size={10}
                                      aria-label="Loading Spinner"
                                      data-testid="loader"
                                    />
                                  ) : null
                                }
                                type="submit"
                                // onClick={async () => {
                                //   setloadingStripe(true);
                                //   const postData = {
                                //     // amount: 10000,
                                //     // paymentMethod: id,
                                //     // paymentMethodId: id,
                                //     // user_email: email,
                                //     subscriberEmail: EmailAddress,
                                //     // selected_priceId: "price_1NY1EuGui44lwdb4IX632ZW2",
                                //     planId: priceIdPaypal,
                                //     PlanName: packageName,
                                //     subscriberName: nameData,
                                //     price_id: priceId,
                                //     return_url: `${window.location.origin}/checkout_page/${priceId}`,
                                //     cancel_url: `${window.location.origin}/cancel`,
                                //     // "user_Id": "100004"
                                //   };
                                //   const apiData = await post(
                                //     "create-subscription",
                                //     postData
                                //   ); // Specify the endpoint you want to call
                                //   console.log(apiData);
                                //   if (apiData.error) {
                                //     console.log("error true");
                                //     // setLoader(false)
                                //   } else {
                                //     console.log("DATAB");
                                //     console.log(apiData);

                                //     // Find the approval URL
                                //     const approvalLink =
                                //       apiData.subscription.links.find(
                                //         (link) =>
                                //           link.rel === "approve" &&
                                //           link.method === "GET"
                                //       );
                                //     console.log(approvalLink);
                                //     // Navigate to the approval link
                                //     if (approvalLink) {
                                //       window.location.href = approvalLink.href;
                                //     } else {
                                //       console.error("Approval link not found");
                                //     }
                                //   }
                                // }}
                                disabled={loadingStripe}
                                className="poppinsRegularBold"
                                sx={{
                                  width: "80%",
                                  marginTop: "5%",
                                  color: "white",
                                  bgcolor: "#6a34d4",
                                  fontWeight: 700,
                                  borderRadius: "10px",
                                  //center button

                                  fontSize: "16px",
                                  transition:
                                    "background-color 0.3s, color 0.3s", // Add transition properties
                                  "&:hover": {
                                    bgcolor: "white",
                                    color: "#6a34d4",
                                    border: "1px solid #6a34d4", // Change text color to blue on hover
                                    transitionDelay: "0.3s", // Add transition delay for hover
                                  },
                                  "&.Mui-disabled": {
                                    color: "white",
                                    backgroundColor: "#6a34d4",
                                    opacity: 0.6,
                                    cursor: "not-allowed",
                                    border: "none",
                                  },
                                  marginLeft: "10px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Pay with Stripe
                              </Button>
                              <Box
                                style={{
                                  marginTop: "1%",
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                }}
                              >
                                {/* {*<span
                                  style={{ fontWeight: 700, fontSize: "20px" }}
                                >
                                  Powered by
                                </span>*} */}
                                <Avatar
                                  src={cardImages}
                                  variant="square"
                                  style={{
                                    width: "350px",
                                    height: "auto",
                                    marginLeft: "10px",
                                  }}
                                />
                              </Box>
                            </Box>

                            {/* <button disabled={loadingStripe} className="axil-btn btn-fill-primary btn-medium mt-3">Pay</button> */}
                          </form>
                          {/* :
                                            <div className="payment-success" style={{ marginTop: '20%' }}>
                                                <h2>Payment successful</h2>

                                                <h3 className='Thank-you'>Thank you for your patronage</h3>
                                               
                                            </div>
                                        } */}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} style={{ height: "110vh" }}>
              <Grid item xs={12} md={2}></Grid>
              <Grid
                item
                xs={12}
                md={8}
                align="center"
                style={{ marginBlock: "10%" }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    align="center"
                    style={{ padding: "2%" }}
                  >
                    <Avatar
                      variant="square"
                      src={cardImage2}
                      id="ImageHide"
                      alt="Circle"
                      style={{
                        height: "auto",
                        boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                        width: "90%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    align="center"
                    style={{ paddingBlock: "10%" }}
                  >
                    <div
                      className="payment-success"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        className="poppinsRegularBold"
                        style={{
                          fontSize: "30px",
                          color: "rgb(18 35 114)",
                          fontWeight: 700,
                        }}
                      >
                        Payment Successful
                      </Typography>
                      <Typography
                        variant="h5"
                        id="subheader1"
                        className="poppinsLight"
                        style={{ color: "#041d34", fontweight: 700 }}
                      >
                        Thank you for your patronage.
                      </Typography>
                      <Button
                        onClick={() => navigate(`/`)}
                        className="poppinsRegularBold"
                        sx={{
                          width: "auto",
                          marginTop: "5%",
                          color: "white",
                          bgcolor: "#6a34d4",
                          borderRadius: "10px",
                          fontWeight: 700,
                          //center button
                          fontSize: "16px",
                          transition: "background-color 0.3s, color 0.3s", // Add transition properties
                          "&:hover": {
                            bgcolor: "white",
                            color: "#6a34d4",
                            border: "1px solid #6a34d4", // Change text color to blue on hover
                            transitionDelay: "0.3s", // Add transition delay for hover
                          },
                          marginLeft: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        Back to Home{" "}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
