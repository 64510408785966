import { Avatar, Grid, Stack, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ContainedButton from '../Buttons/ContainedButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import circle from "../../assets/circle.png"
import HeroImage from "../../assets/logo.jpg"
import ScrollAnimation from 'react-animate-on-scroll';
import googleMaps from "../../assets/companies/1.png"
import Cloud from "../../assets/companies/2.png"
import Firebase from "../../assets/companies/3.png"
import ChatGPT from "../../assets/companies/ChatGPT-API.png"
import AWS from "../../assets/companies/5.png"
import agora from "../../assets/companies/6.png"
import { BASE_URL, get } from '../../urls/api';
function CompaniesSection() {
    const [images, setImages] = React.useState([])
    const fetchCompanies=async()=>{
       
          try {
            const apiData = await get('company/getAllCompanys'); // Specify the endpoint you want to call
            console.log(apiData)
            if (apiData.error) {
            console.log("error true")
            } else {
                console.log(apiData.result)
                setImages(apiData.result)
            }
          } catch (error) {

          }
      
    }

    React.useEffect(() => {
        fetchCompanies();
    
      }, []);
    return (
        <>
        {/* large screen  */}
            <Grid id='companiessectionMd' container spacing={2} style={{ paddingBlock:'5%', backgroundColor: 'white' }}>
                <Grid item xs={12} md={12} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Typography className='poppinsSemiRegularBold' variant='h3' style={{ color: '#6a34d4', marginBlock: '2%' }}>
                               Offerings
                            </Typography>
                    </Grid>
                <Grid item xs={12} md={1}>

                </Grid>
                <Grid item xs={12} md={10}>
                    {/* <Stack display="flex" direction="row" justifyContent="space-between"> */}
                    <Grid container>
                        {images.map((image) => (
                            <Grid item xs={12} md={2} align="center">
                                <ScrollAnimation animateIn="fadeIn">
                                    <Avatar variant="square" id="companies" style={{
                                         width: '200px', 
                                    height: '100px', 
                                    filter: 'saturate(100%)',
                                    // opacity: 0.7,
                                    transition: 'filter 0.3s', 
                                 
                                    }} src={`${BASE_URL}${image.image}`} />

                                </ScrollAnimation >

                            </Grid>
                        ))}
                    </Grid>

                    {/* </Stack> */}
                </Grid>
                <Grid item xs={12} md={1}>

                </Grid>
            </Grid>
{/* small screen  */}
            <Grid id='companiessectionMdSmall' container spacing={2} style={{ paddingBlock:'5%', backgroundColor: 'white',height:'100%' }}>
                <Grid item xs={12} md={1}>

                </Grid>
                <Grid item xs={12} md={10}>
                    {/* <Stack display="flex" direction="row" justifyContent="space-between"> */}
                    <Grid container>
                        {images.map((image) => (
                            <Grid item xs={12} md={2} align="center" >
                                <ScrollAnimation animateIn="fadeIn">
                                    <Avatar variant="square" id="companies" style={{
                                         width: '200px', 
                                    height: 'auto', 
                                    filter: 'saturate(100%)',
                                    // opacity: 0.7,
                                    transition: 'filter 0.3s', 
                                 
                                    }} src={`${BASE_URL}${image.image}`} />

                                </ScrollAnimation >

                            </Grid>
                        ))}
                    </Grid>

                    {/* </Stack> */}
                </Grid>
                <Grid item xs={12} md={1}>

                </Grid>
            </Grid>

        </>
    );
}

export default CompaniesSection;