import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"

function ContainedButton({text,onClick,disabled,startIcon}) {
    return (
        <>
 <Button
                                    className='poppinsSemiRegularBold'
                                    // id="containedButton"
                                    onClick={onClick}
                                    disabled={disabled}
                                    startIcon={startIcon}
                                    sx={{
                                        // width: '250px',
                                        backgroundColor: '#6a34d4',
                                        color: 'white',
                                        fontWeight: 700,
                                        fontSize: '20px',
                                        padding: '10px 20px',
                                        borderRadius: '10px',
                                        textTransform: 'none',
                                        // bgcolor: "#007aff",
                                        // borderRadius: '50px',
                                        // fontSize: '19px',
                                        transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                        '&:hover': {
                        bgcolor: 'white',
                        color: '#6a34d4',
                        border: '1px solid #6a34d4', // Change text color to blue on hover
                        transitionDelay: '0.2s', // Add transition delay for hover
                   
                    },
                                    }}
                                >{text}</Button>
            {/* <Button
                className='poppinsRegularBold'
                id="containedButton"
                onClick={onClick}
                sx={{
                    // width: '250px',
                    backgroundColor: 'white',
                    color: '#007aff',
                    // bgcolor: "#007aff",
                    // borderRadius: '50px',
                    // fontSize: '19px',
                  transition: 'background-color 0.3s, color 0.3s', // Add transition properties

                    '&:hover': {
                        bgcolor: "white",
                        color: '#007aff',
                        border: '1px solid #007aff', // Change text color to blue on hover
                        transitionDelay: '0.3s', // Add transition delay for hover
                   
                    },
                    marginLeft: '10px',
                    textTransform: 'capitalize'

                }}>{text}</Button> */}
        </>
    );
}

export default ContainedButton;