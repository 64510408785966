import { Avatar, Box, Grid, Skeleton, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import { BASE_URL } from '../../urls/api';





function Products({ features }) {


    return (
        <>
            {/* <Box > */}
            <Grid container spacing={2} style={{ backgroundColor: 'white', paddingBlock: '4%' }}>
                {
                    features === null || features === undefined ? <>
                    
                        <Grid item xs={12} md={6}>
                            <Skeleton variant="rectangular" height={300} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Skeleton variant="rectangular" height={300} />

                        </Grid>
                    </> :
                        <>
                        {window.innerWidth<730?
                        <>
                         {features.length === 0 ? <>
                            </> :
                                <>  {features.map((feature, index) => (
                                    <>

                                            <Grid item xs={12} md={6} align="center" style={{ padding: '20px',marginTop:"20px" }}>
                                                <Box style={{ padding: '20px', width: '90%', height: '100%', borderRadius: '30px'}}>
                                                    <Avatar variant='square' src={`${BASE_URL}${feature?.image}`} alt="Hero Image"
                                                        style={{
                                                            // position: 'sticky',
                                                            width: '250px',
                                                            height: "250px",
                                                            objectFit:"contain",
                                                            borderRadius: '20px',
                                                        }} />
                                             <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34', marginBlock: '10px',
                                                wordWrap: 'break-word', // Ensures long words break to the next line
                                                wordBreak: 'break-word', // Breaks long words if needed
                                                overflowWrap: 'break-word',
                                              }}>
                                                            {feature?.title}
                                                        </Typography>
                                                        <Typography variant='h6' className='poppinsLight' style={{ color: '#041d34', lineHeight: 2, fontweight: 700, width: '90%',
                                                            wordWrap: 'break-word', // Ensures long words break to the next line
                                                            wordBreak: 'break-word', // Breaks long words if needed
                                                            overflowWrap: 'break-word',
                                                         }}>
                                                            {feature?.description}
                                                        </Typography> 
                                                           </Box>
                                            </Grid>

                                           

                                    </>
                                ))

                                }
                                </>
}</>:
                        <> {features.length === 0 ? <>
                            </> :
                                <>  {features.map((feature, index) => (
                                    <>
                                        {index % 2 === 0 ? <>

                                            <Grid item xs={12} md={6} align="center" style={{ padding: '20px' }}>
                                                <Box style={{ backgroundColor: `#b597f3`, padding: '20px', width: '70%', height: window.innerWidth<730?"100%":'70vh', borderRadius: '30px', position: 'relative' }}>
                                                    <Avatar variant='square' src={`${BASE_URL}${feature?.image}`} alt="Hero Image"
                                                        style={{
                                                            position: 'absolute',
                                                            // position: 'sticky',
                                                            width: '80%',
                                                            height: '80%',
                                                            objectFit:"contain",
                                                            borderRadius: '20px',
                                                            top: "10%",
                                                            bottom:"10%",
                                                            left: '10%',
                                                            display: { xs: 'none', md: 'block' }
                                                        }} />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} md={5} id="featuresSectionsDiv" >
                                                <Grid container >
                                                    <Grid item xs={12} md={12}  >
                                                        <Typography className='poppinsSemiRegularBold' variant='h2' style={{ color: '#041d34', marginBottom: '10px' }}>
                                                            {feature?.title}
                                                        </Typography>
                                                        <Typography variant='h5' className='poppinsLight' style={{ color: '#041d34', lineHeight: 2, fontweight: 700, width: '90%' }}>
                                                            {feature?.description}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                            </Grid>
                                        </> :
                                            <>
                                                <Grid item xs={12} md={1}>
                                                </Grid>
                                                <Grid item xs={12} md={5} id="featuresSectionsDiv" >
                                                    <Grid container >
                                                        <Grid item xs={12} md={12}  >
                                                            <Typography className='poppinsSemiRegularBold' variant='h2' style={{ color: '#041d34', marginBottom: '10px' }}>
                                                                {feature?.title}
                                                            </Typography>
                                                            <Typography variant='h5' className='poppinsLight' style={{ color: '#041d34', lineHeight: 2, fontweight: 700, width: '90%' }}>
                                                                {feature?.description}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} md={6} align="center" style={{ padding: '20px' }}>
                                                    <Box style={{ backgroundColor: `#6934d4`, padding: '20px', width: '70%', height: window.innerWidth<730?"100%":'70vh', borderRadius: '30px', position: 'relative' }}>
                                                        <Avatar variant='square' src={`${BASE_URL}${feature?.image}`} alt="Hero Image"
                                                            style={{
                                                                position: 'absolute',
                                                                // position: 'sticky',
                                                                width: '80%',
                                                                height: '80%',
                                                                objectFit:"contain",
                                                                borderRadius: '20px',
                                                                top: "10%",
                                                                bottom:"10%",
                                                                left: '10%',
                                                                display: { xs: 'none', md: 'block' }
                                                            }} />
                                                    </Box>
                                                </Grid>
                                            </>}

                                    </>
                                ))

                                }
                                </>
}
                        </>}
                           
                        </>
                }





            </Grid>
            {/* </Box> */}
        </>
    );
}

export default Products;