// import logo from './logo.svg';
import * as React from 'react';
import '../App.css';
import AppbarHomw from '../components/Appbar';
import ProductHero from '../components/ProductHero';
import Products from '../components/Products';
import PricingSection from '../components/PricingPage';
import { useParams } from 'react-router-dom';
import { post } from '../urls/api';
import Footer from '../components/Footer';

function ProductDetails() {
  const firstSectionRef = React.useRef(null);
  const { id } = useParams();
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [image, setImage] = React.useState('');
  const [features, setFeatures] = React.useState('');
  const [pricing, setPricing] = React.useState('');
  const [StripePricingId, setStripePricingId] = React.useState('');
  const [dataFetched, setDataFetched] = React.useState(false);

  const fetchProduct = async () => {
    try {
      const postData = { product_id: id };
      const apiData = await post('package/getPackageByProductId', postData);
      console.log("apiData", apiData);

      if (apiData.error) {
        console.log("error true");
      } else {
        console.log(apiData.result);
        setName(apiData?.data?.product?.product_name);
        setDescription(apiData?.data?.product?.description);
        setImage(apiData?.data?.product?.image);
        setFeatures(apiData?.data?.product?.features);
        setPricing(apiData?.data?.prices);
        setDataFetched(true); // Mark data as fetched and set the state to trigger the scroll effect
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchProduct();
  }, []);
  const pricingSection1 = document.getElementById('pricing');

  React.useEffect(() => {
    if (dataFetched) {
      // Check if the URL contains #pricing and scroll to it
      const hash = window.location.hash;
      if (hash === '#pricing') {
        const pricingSection = document.getElementById('pricing');
        if (pricingSection) {
          pricingSection.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If no specific hash, scroll to the first section
        if (firstSectionRef.current) {
          firstSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, [dataFetched]);

  return (
    <>
      {/* Appbar  */}
      <div style={{ backgroundColor: '#f5f8fa' }} ref={firstSectionRef}>
        <AppbarHomw />
        {/* Appbar end */}
        <div>
          <ProductHero name={name} description={description} image={image} pricingSection1={pricingSection1}/>
        </div>
        <Products features={features} />
        <div id="pricing">
          <PricingSection features={features} pricing={pricing} pricingId={StripePricingId} />
        </div>
        <Footer />
      </div>
    </>
  );
}
export default ProductDetails;

