import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import imageAvatar from "../assets/download.png"
// Footer 
export const emailKey = ` support@fyniz.com`; // email key conatct us 
export const phoneNoKey=`+971 58 596 7712`;
export const locationKey=`1942 BROADWAY STE 314C BOULDER, CO 80302-437`
export const FooterContent=`Leverage our state-of-the-art technology, tailored to meet the unique demands of your evolving business landscape.`
// hero section home 
export const heroheaderPart1=`All Digital Solutions ,`
export const heroheaderPart2=`All here`
export const heroParagraph=` Dive deep into the digital universe with our unparalleled subscription services.`
// About us 
export const aboutUsHead=`About us`
export const aboutUsHeader=`Fyniz.com`
export const aboutUsPara=` Discover Fyniz.com, where your digital aspirations meet our innovative solutions. We provide a suite of essential tools and APIs to fuel your projects' growth and efficiency. Embrace the ease of integration and support that propels your venture forward. With Fyniz.com, harness the power to excel in the digital realm.`
// Products 
export const productsHead=` Our Product`
export const productsParaPart1=` Unlock Digital Excellence with Fyniz.com Subscriptions`
export const productsParaPart2=`Your One-Stop for APIs and Digital Products!`
// Features 
export const Featureheader=`Our Features`
export const FeaturePara=` Unlock a suite of advanced features with Fyniz.com: seamless integrations, sophisticated analytics, and tailored toolkits. Elevate your digital projects with our technology designed for dynamic business needs.`

export const featureArray=[
    {
        header: 'Easy Subscription',
        color: '#ba98ff',
        icon: <AttachMoneyIcon style={{ color: 'white', fontSize: '100px' }} />,
        paragraph: `Tailored options for your convenience`,
        key: 1
    },
    {
        header: '24/7 Support',
        icon: <AccessTimeIcon style={{ color: 'white', fontSize: '100px'}} />,
        color: '#9769f4',
        paragraph: `Help whenever you need it on the go.`,
        key: 2
    },
    {
        header: 'Secure Payment',
        icon: <CreditCardIcon style={{ color: 'white', fontSize: '100px'}} />,
        color: '#7b3ef4',
        paragraph: `Your financial information, our priority.`,
        key: 3
    },
    {
        header: 'Data Security',
        icon: <LockIcon style={{ color: 'white', fontSize: '100px' }} />,
        paragraph: 'Protecting your data, ensuring trust.',
        color: '#6934d4',
        key: 4
    },
  

]

// Slider 
export const sliderSArray=[
    {
        text: `With Fyniz.com's seamless integrations, our deployment cycles have been cut in half. Their platform is now a staple in our DevOps toolkit.`,
        image: imageAvatar,
        key: 1,
        commenter_name: 'Samantha Lee',
        commenter_designation: 'Project Manager',

    },
    {
        text: `As a freelancer, I need tools that are flexible and powerful. Fyniz.com delivers on both fronts, making it my go-to for all my web development projects.`,
        image: imageAvatar,
        key: 2,
        commenter_name: 'Melissa Chong',
        commenter_designation: 'Freelance Web Developer',
    },
    {
        text: `Fyniz.com's robust APIs have been crucial in driving our digital transformation initiatives. Their reliability is unmatched, making them a valued partner.`,
        image: imageAvatar,
        key: 3,
        commenter_name: 'Raj patel',
        commenter_designation: 'Head of IT at FinTech Global',
    },
    {
        text: `I'm thoroughly impressed with Fyniz.com's attention to detail. Their tools have helped refine our design process, ensuring a seamless user experience.`,
        image: imageAvatar,
        key: 4,
        commenter_name: `Sophies D'Souza`,
        commenter_designation: 'UI/UX Designer',
    },

]
// Testimonials 
export const testimonialHeaderPart1=`All Digital Solutions,`
export const testimonialHeaderPart2=`All here`


// _------------------------------------------ABOUT US _________________________
export const aboutUsHeaderHero=`Unlock Possibilities`
export const aboutUsParaHero=`Our subscription offerings provide reliable and affordable access to the world's most popular mapping platform, so you can focus on what matters most - growing your business.`

// ----------------------------------------------Products ---------------------
export const productsHeaderHero=`Browse Wide Selection`
export const productsParaHero=`Unlock the potential of the digital realm with our groundbreaking subscription offerings.`

// ----------------------------------------------Contact Us  ---------------------

export const contactUsHeaderHero=`Get in Touch`
export const contactUsParaHero=` Have a question or need help with our products? Our team is always here to assist you. Contact us today and let us help you unlock the full potential of digital maps for your business.`

export const contactusCardHead=`24/7 Support`
export const contactusCardPara=`Have a question or need help with our products? Our team is always here to assist you. Contact us today and let us help you unlock the full potential of digital maps for your business.`

export const contactUsMessageAlert=`Message Sent . We will contact you soon !`
export const contactUsMessageAlertError=`Something went Wrong`




