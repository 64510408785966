import { Avatar, Grid, Button, Alert, Collapse, IconButton, TextField, Typography, CardContent, Card, Box } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import './stylesheet.css'
import { post } from '../../urls/api';
import toastAlert from '../ToastAlert';
import { ClipLoader } from 'react-spinners';
import CloseIcon from '@mui/icons-material/Close';
import { contactUsMessageAlert, contactUsMessageAlertError, contactusCardHead, contactusCardPara } from '../../urls/contactusKeys';
import ContainedButton from '../Buttons/ContainedButton';

function ContactCard() {
    const override = {
        display: "block",
        margin: "0 auto",
    };
    const [loader, setLoader] = React.useState(false)
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [msgAlert, setMessageAlert] = React.useState('success');



    const SendMessage = async () => {
        setLoader(true)

        console.log("Sending Message")
        if (name === "" || email === "" || message === "") {
            toastAlert("error", "Please fill all fields")
            setLoader(false)
        } else {
            // api call 
            try {
                const postData = {
                    name: name,
                    email: email,
                    message: message
                }
                const apiData = await post('contact_us/createContact', postData); // Specify the endpoint you want to call
                console.log(apiData)
                if (apiData.error) {
                    console.log("error true")
                    setLoader(false)
                    // toastAlert("error", "Something went wrong")
                    setMessageAlert('error')
                    setOpen(true)
                } else {
                    setLoader(false)
                    setMessageAlert('success')
                    setOpen(true)
                    // toastAlert("success", "Message Sent . We will contact you soon !")
                    setName('')
                    setEmail('')
                    setMessage('')
                }
            }
            catch (error) {
                setLoader(false)
            }
        }
    }
    return (
        <>
{window.innerWidth<730?
    <Grid container spacing={2}
                id="smallScreenHero"
                style={{

                    marginTop: 100,
                    // backgroundColor:'red',

                    // height: '100vh',
                    display: 'flex',
                    alignItems: 'center',


                }}>
                <Grid item xs={12} md={1}>
                </Grid>
                <Grid item xs={12} md={5}  style={{ position: 'relative' }}>
                    {/* <Avatar src={circle} alt="Circle" variant='square' style={{
                        width: '90%', height: 'auto',
                        borderRadius: '50%',
                        display: { xs: 'none', md: 'block' }
                    }} /> */}
                    <Card

                        sx={{
                            // maxWidth: 545,
                            boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            transition: 'transform 0.3s', // Add transition for zoom effect
                            '&:hover': {
                                transform: 'scale(1.02)' // Zoom effect on hover
                            }
                        }}>
                        
                        <Box style={{ boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)', padding: '4%' }}>
                            <CardContent style={{ textAlign: 'left',fontWeight:800 }}>
                            <Collapse in={open}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {msgAlert === "success" ? <>
                                    {contactUsMessageAlert}
                                </> :
                                    <>
                                      {contactUsMessageAlertError}  </>}

                            </Alert>
                        </Collapse>
                                <div
                                // onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                                >
                                    <Typography className="poppinsSemiRegularBold"

                                        style={{
                                            fontSize: '20px',
                                        }}
                                    >
                                        Contact Us
                                    </Typography>
                                    <TextField label="Name" type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px',fontWeight:700 }} />
                                    <TextField label="Email" type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px',fontWeight:700 }} />
                                    <TextField label="Message" type="text"
                                        multiline
                                        rows={4}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px' ,fontWeight:700}} />
                                    {/* <Button
                                        onClick={() => SendMessage()}
                                        disabled={loader}
                                        startIcon={loader ?
                                            <ClipLoader
                                                color="Gray"
                                                loading={loader}
                                                cssOverride={override}
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : null}
                                        className='poppinsRegularBold'
                                        sx={{
                                            width: '30%',
                                            marginTop: '2%',
                                            color: 'white',
                                            bgcolor: "#007aff",
                                            borderRadius: '50px',
                                            //center button
                                            fontSize: '16px',
                                            transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                            '&:hover': {
                                                bgcolor: "white",
                                                color: '#007aff',
                                                border: '1px solid #007aff', // Change text color to blue on hover
                                                transitionDelay: '0.3s', // Add transition delay for hover
                                            },
                                            // disable button css 
                                            '&.Mui-disabled': {
                                                color: 'white',
                                                backgroundColor: '#007aff',
                                                opacity: 0.6,
                                                cursor: 'not-allowed',
                                                border: 'none',
                                            },
                                            marginLeft: '10px',
                                            textTransform: 'capitalize'

                                        }}>Submit</Button> */}
                                            <ContainedButton  onClick={() => SendMessage()}
                                        disabled={loader}
                                        startIcon={loader ?
                                            <ClipLoader
                                                color="white"
                                                loading={loader}
                                                cssOverride={override}
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : null}text="Submit" />


                                </div>

                            </CardContent>

                        </Box>
                    </Card>


                </Grid>
                <Grid item xs={12} md={1} id="smallScreenImageLarge">
                </Grid>
                <Grid item xs={12} md={5} >
                    <Grid container>
                        <Grid item xs={12} md={12} style={{marginBlock:"20px"}}>
                            <Typography className='poppinsSemiRegularBold' variant='h3' style={{ color: 'white', marginBottom: '10px', width: '100%' }}>
                                {contactusCardHead}
                            </Typography>
                            <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                                {contactusCardPara}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>





            </Grid>:

            <Grid container spacing={2}
                id="smallScreenHero"
                style={{

                    marginTop: 30,
                    // backgroundColor:'red',

                    // height: '100vh',
                    display: 'flex',
                    alignItems: 'center',


                }}>
                <Grid item xs={12} md={1}>
                </Grid>
                <Grid item xs={12} md={5} mt={-5} style={{ position: 'relative' }}>
                    {/* <Avatar src={circle} alt="Circle" variant='square' style={{
                        width: '90%', height: 'auto',
                        borderRadius: '50%',
                        display: { xs: 'none', md: 'block' }
                    }} /> */}
                    <Card

                        sx={{
                            // maxWidth: 545,
                            boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            transition: 'transform 0.3s', // Add transition for zoom effect
                            '&:hover': {
                                transform: 'scale(1.02)' // Zoom effect on hover
                            }
                        }}>
                        
                        <Box style={{ boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)', padding: '4%' }}>
                            <CardContent style={{ textAlign: 'left',fontWeight:800 }}>
                            <Collapse in={open}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {msgAlert === "success" ? <>
                                    {contactUsMessageAlert}
                                </> :
                                    <>
                                      {contactUsMessageAlertError}  </>}

                            </Alert>
                        </Collapse>
                                <div
                                // onClick={() => navigate(`/product_detail/${feature.product_id}`)}
                                >
                                    <Typography className="poppinsSemiRegularBold"

                                        style={{
                                            fontSize: '20px',
                                        }}
                                    >
                                        Contact Us
                                    </Typography>
                                    <TextField label="Name" type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px',fontWeight:700 }} />
                                    <TextField label="Email" type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px',fontWeight:700 }} />
                                    <TextField label="Message" type="text"
                                        multiline
                                        rows={4}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                        style={{ width: "100%", marginBlock: '3%', borderRadius: '5px' ,fontWeight:700}} />
                                    {/* <Button
                                        onClick={() => SendMessage()}
                                        disabled={loader}
                                        startIcon={loader ?
                                            <ClipLoader
                                                color="Gray"
                                                loading={loader}
                                                cssOverride={override}
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : null}
                                        className='poppinsRegularBold'
                                        sx={{
                                            width: '30%',
                                            marginTop: '2%',
                                            color: 'white',
                                            bgcolor: "#007aff",
                                            borderRadius: '50px',
                                            //center button
                                            fontSize: '16px',
                                            transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                                            '&:hover': {
                                                bgcolor: "white",
                                                color: '#007aff',
                                                border: '1px solid #007aff', // Change text color to blue on hover
                                                transitionDelay: '0.3s', // Add transition delay for hover
                                            },
                                            // disable button css 
                                            '&.Mui-disabled': {
                                                color: 'white',
                                                backgroundColor: '#007aff',
                                                opacity: 0.6,
                                                cursor: 'not-allowed',
                                                border: 'none',
                                            },
                                            marginLeft: '10px',
                                            textTransform: 'capitalize'

                                        }}>Submit</Button> */}
                                            <ContainedButton  onClick={() => SendMessage()}
                                        disabled={loader}
                                        startIcon={loader ?
                                            <ClipLoader
                                                color="white"
                                                loading={loader}
                                                cssOverride={override}
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            /> : null}text="Submit" />


                                </div>

                            </CardContent>

                        </Box>
                    </Card>


                </Grid>
                <Grid item xs={12} md={1} id="smallScreenImageLarge">
                </Grid>
                <Grid item xs={12} md={5} >
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography className='poppinsSemiRegularBold' variant='h3' style={{ color: 'white', marginBottom: '10px', width: '100%' }}>
                                {contactusCardHead}
                            </Typography>
                            <Typography variant='h5' id="subheader" className='poppinsLight' style={{ color: 'white', fontweight: 700 }}>
                                {contactusCardPara}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>





            </Grid>}
        </>
    );
}

export default ContactCard;