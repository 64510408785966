import {  Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { FeaturePara, Featureheader, featureArray } from '../../urls/contactusKeys';
function Features() {
 
    return (
        <>

            <Grid container spacing={2} pt={10} pb={20} style={{
                backgroundColor: 'white'
            }}>
                <Grid item xs={12} md={1}>
                </Grid>
                <Grid item xs={12} md={10} >
                    <Grid container>
                        <Grid item xs={12} md={12} align="center">
                            {/* <Typography className='poppinsSemiRegularBold' variant='h4' style={{ color: '#041d34' }}>
                            Our Product
                            </Typography> */}
                            <Typography id="productHeader" className='poppinsSemiRegularBold' variant='h2' style={{ color: '#6a34d4', marginBottom: '20px', width: '100%' }}>
                                {Featureheader}
                            </Typography>
                            <Typography className="poppinsRegularBold" style={{ fontSize: '16px', marginTop: '10px',width:'75%' }}>
                               {FeaturePara}

                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={12} align="center" mt={5}>
                            <Grid container spacing={1}>
                                {featureArray.map((feature) => (
                                    <>

                                        <Grid item xs={12} md={3} mt={2} align="center">
                                            <ScrollAnimation animateIn="fadeIn">

                                                <Box id="featureIcon" style={{ position: 'relative', borderRadius: '50%', backgroundColor: `${feature.color}` }}>
                                                    {feature.icon}
                                                </Box>
                                                <Typography className='poppinsSemiRegularBold' variant='h5' style={{ color: '#041d34', marginBottom: '10px', marginTop: '20px', width: '60%' }}>
                                                    {feature.header}
                                                </Typography>
                                                <Typography variant='h6' className='poppinsLight' style={{ color: '#041d34', lineHeight: 1.5, width: '60%' ,fontSize: '15px'}}>
                                                    {feature.paragraph}
                                                </Typography>
                                            </ScrollAnimation>

                                        </Grid>
                                    </>))}
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={2}>

                        </Grid>


                    </Grid>


                </Grid>
                <Grid item xs={12} md={3}>
                </Grid>

            </Grid>
        </>
    );
}

export default Features;