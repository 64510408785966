import { Avatar, Grid, Typography } from '@mui/material';
import * as React from 'react';
import "../../App.css"
import Slider from "react-slick";
import imageAvatar from "../../assets/avatar.png"
import { sliderSArray } from '../../urls/contactusKeys';

function SliderSection() {
   
    const settings = {
        dots: true,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        // autoplay: true,
        speed: 500,

        // autoplaySpeed: 500,

    };
    return (
        <>

            <Grid container spacing={2} mt={16} style={{ backgroundColor: '#f5f8fa' }}>
                <Grid item xs={12} md={1}>
                </Grid>
                <Grid item xs={12} md={10} >
                    <div>
                        <Slider {...settings}>
                            {sliderSArray.map((item) => (
                                <div>
                                    {/* <h3>1</h3> */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={2} align="center">
                                        </Grid>
                                        <Grid item xs={12} md={8} align="center" >
                                            <Typography variant='h3'id="sliderText" style={{
                                                fontSize: '35px',

                                            }} className='poppinsRegularBold' >
                                                {item.text}
                                            </Typography>
                                            <Avatar src={item.image} alt="imageAvatar" style={{ marginTop: '40px' }} />
                                            <Typography variant='h6' id="sliderComenter" className='poppinsSemiRegularBold' >
                                                {item.commenter_name}
                                            </Typography>
                                            <Typography variant='h6' id="sliderComenter" className='poppinsLight'  >
                                                {item.commenter_designation}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2} align="center">
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </Slider>
                    </div>


                </Grid>
                <Grid item xs={12} md={1} >


                </Grid>
            </Grid>
        </>
    );
}

export default SliderSection;